.adminHeader {
  width: 100%;
  box-shadow: 0 0 3px;
  padding: 10px;
  background-color: var(---primaryColour);
  position: fixed;
  top: 0;
  left: 0;
}

.loginDetail,
.loginDetail .loginName {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginDetail {
  position: relative;
}

.loginName h3 {
  margin: unset;
  margin-left: 10px;
  color: #fff;
  font-size: 1.4rem;
  cursor: pointer;
}

.loginDetail ul {
  position: absolute;
  bottom: -100px;
  box-shadow: 0 0 2px;
  border-radius: 3px;
  background-color: #fff;
  padding: 10px;
  min-width: 200px;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.loginDetail.active ul{
    transition: 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
    z-index: unset;
    bottom: -200px;
    z-index: 1;
}

.loginDetail ul li {
  cursor: pointer;
  position: relative;
  padding: 5px 10px;
  z-index: 1;
  transition: 0.5s ease-in-out linear;
  border-radius: 3px;
  background: linear-gradient(to right, #eee 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.loginDetail ul li:hover {
    background-position: left bottom;
}