.section_profile {
  margin: 80px 0px;
}

.section_profile .Edit_profileCard {
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.newProfile_card {
  padding: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}

.section_profile .tab-content > .tab-pane {
  background-color: white;
}

.section_profile .Edit_profilecard-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.section_profile .Edit_profile-img {
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50%;
}
.section_profile .Edit_profile-img img {
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 50%;
}

.section_profile .Edit_profile-Txt h2 {
  margin-top: 10px;
  font-size: 24px;
  text-align: center;
  font-family: var(---primaryFont);
}

.section_profile .Edit_profile-Txt h3 {
  font-size: 14px;
  text-align: center;
}

.section_profile .profile_social-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.section_profile .profile_social-links a {
  color: #666;
  margin-right: 20px;
  font-size: 20px;
}

/* .section_profile .nav-tabs .nav-link{
  color:#4a6cf7 !important;
} */

/* =========== Edit OverView Section ========== */
.edit_Overview {
  padding: 10px;
}

.edit_Overview h5 {
  font-family: var(---primaryFont);
  font-size: 22px;
  margin-bottom: 20px;
}
.edit_Overview p {
  font-style: italic;
  font-size: 14px;
  margin-bottom: 15px !important;
}
.edit_Overview .overForm_list h5 {
  font-size: 14px;
  font-family: var(---primaryFont);
}
.edit_Overview .overForm_detail p {
  font-size: 14px;
  margin-bottom: 10px;
  color: #808080;
  font-family: var(---primaryFont);
}

/* =========== Edit Profile Section ========== */
.Edit_profileSec {
  padding: 15px;
}

.Edit_profileSec .profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.Edit_profileSec .profile-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.profile_imgEdit {
  padding-top: 10px;
}
.profile_imgEdit a {
  margin-right: 10px;
}
.profile_imgEdit a i svg {
  color: white;
  font-size: 18px;
  font-weight: 700;
}
.profle_headings h5 {
  font-size: 16px;
  font-family: var(---primaryFont);
}

.editProfle button {
  color: white;
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 7px 15px;
  background-color: #4a6cf7;
}

/* =============== Edit Setting Section ============ */
.Edit_settingSec {
  padding: 15px;
}

.setting_Save {
  text-align: center;
  margin-top: 20px;
}

.setting_Save button {
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 7px 15px;
  background-color: #4a6cf7;
  text-align: center;
  color: white;
  text-align: center !important;
}
.setting_Save button:hover {
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 7px 15px;
  background-color: #4a6cf7;
  text-align: center;
  text-align: center !important;
  color: white;
}

/* =============== Edit Password Changes Section ============ */
.Edit_passwordSec {
  padding: 15px;
}

.pass_headings h5 {
  font-size: 16px;
}
.pass_chang {
  text-align: center;
  margin-top: 20px;
}

.pass_chang button {
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
  padding: 7px 15px;
  background-color: #4a6cf7;
  text-align: center;
  text-align: center !important;
}
.pass_chang button:hover {
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 7px 15px;
  background-color: #4a6cf7;
  text-align: center;
  text-align: center !important;
  color: white;
}
.pagetitle h1 {
  font-family: var(---primaryFont);
  font-size: 30px;
}

.socialIcon svg {
  font-size: 2.5rem;
}

.socialIcon.facebook svg {
  color: #3b5998;
}

.socialIcon.instagram svg {
  color: #fff;
  border-radius: 10px;
  padding: 5px;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.socialIcon.linkedin svg {
  color: #0A66C2;
}

.socialIcon.twitter svg {
  color: #00acee ;
}


.socialIcon.youtube svg {
  color: #CD201F;
}
