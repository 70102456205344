
.Service-Card-Body {
  margin: 20px;
  /* border: 1px solid aqua; */
  height: 60vh;
  position: relative;
  border-radius: 20px;
}

.Service-Card-Body img {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 20px;
}

.Serice-Card-Details-con {
  background: linear-gradient(
    180deg,
    rgba(119, 119, 119, 0.4),
    rgba(255, 255, 255, 0.5)
  );
  /* border: 1px solid red; */
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 20px;
}

.Serice-Card-Details {
  /* border: 1px solid blue; */
  position: absolute;
  bottom: 0;
  left: 0;
}

.Serice-Card-Details h2 {
  font-size: 17px;
  text-transform: capitalize;
  letter-spacing: 2px;
}

.Serice-Card-Details p {
  font-size: 14px;
  text-align: justify;
  padding: 0px 20px;
}

.Service-Card-Drop {
  position: absolute;
  top: 4px;
  right: 10px;
}

.dropdown-toggle::after {
  display: none;
}

.dropdown a {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #00000033; */
  border: none;
}

.dropdown a svg {
  font-size: 20px;
}

/* ///////////// */

.Shedular-Box-card-More-icon {
  /* border: 1px solid gold; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Shedular-Box-card-More-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0px 0px 0px;
}

.Shedular-Box-card-More-icon svg {
  color: white;
  font-size: 25px;
  background-color: none;
}

.Shedular-Box-card-More-icon .MuiButtonBase-root svg {
  font-size: 25px;
  color: whitesmoke;
}

.Shedular-Box-card-More-icon button {
  width: auto;
  min-width: fit-content;
  margin: 0px;
  padding: 0px;
}

/* card dropdown  */

.Shedular-Box-card-More-icon .dropdown-toggle::after {
  display: none;
}

.Shedular-Box-card-More-icon .dropdown-toggle {
  background-color: transparent !important;
}

.Shedular-Box-card-More-icon .dropdown-menu {
  min-width: fit-content !important;
  background-color: whitesmoke;
  transform: translate3d(-70px, 25.3333px, 0px) !important;
  /* box-shadow: 0 0 2px gray; */
}

.dropdown-item:active {
  color: gray !important;
  background-color: none !important;
}

.Shedular-Box-card-More-icon .dropdown-item {
  padding: 0px 0px !important;
  display: flex;
  align-items: center;
  justify-content: left;
}

.Shedular-Box-card-More-icon .dropdown-menu svg {
  font-size: 17px !important;
  color: #474747;
  margin: 0px 10px 0px 10px;
}

.Shedular-Box-card-More-icon .dropdown-menu li {
  padding: 5px 5px;
  border-radius: 25px;
  /* background-color: whitesmoke; */
  font-size: 10px;
}

.Shedular-Box-card-More-icon ul {
  padding: 5px 5px;
  /* background-color: transparent; */
  border: none;
}

.Shedular-Box-card-More-icon .dropdown-item:hover {
  background-color: transparent !important;
}

.carddeleteicon {
  color: #ff384a !important;
}

.carddeleteicon svg {
  color: #ff384a !important;
}

/* /////// Pop Up /////// */

.css-1wnsr1i {
  border: none !important;
  border-radius: 20px;
}

.Des-main-Form-con input {
  border: none !important;
  margin-top: 20px;
}

.subbtn {
  margin-top: 10px;
  text-align: center;
}

.subbtn button {
  margin-top: 10px;
  border: none;
  padding: 7px 20px;
  border-radius: 20px;
}

/* ///////////////////////////////////////// */

.Service-card-con-2 {
  /* border: 1px solid red; */
  padding: 0px 20px 20px 20px;
}

.Service-card-con-2-Main {
  border-radius: 5px;
  box-shadow: 0 0 5px #808080;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 10px 0;
  padding: 10px;
}

.Service-Image-con {
  /* border: 1px solid greenyellow; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.Service-Image {
    border-radius: 5px;
    width: 150px;
    height: 150px;
    object-fit: cover;
}

.Service-Image img {
  position: relative;
  height: 140px;
  width: 150px;
  border-radius: 5px;
}

.Service-Card-Details {
  /* border: 1px solid hotpink; */
  /* margin-top: 20px; */
  padding: 0px 15px 0px 15px;
  text-align: left;
}

.Service-Card-Details h3 {
  font-size: 1.2rem;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-family: var(---primaryFont);
  color: var(---primaryColour);
}

.Service-Card-Details p {
  font-size: 0.9rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  color: #808080;
}


.Service-Edit-Btn {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  margin: 0px 0px 20px 0px;
}

.Service-Remove-Btn {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
}

.Service-Edit-Btn button {
  background-color: rgb(0 30 255 / 15%);
  border: 2px solid blue;
  border-radius: 40px;
  color: blue;
  transition: all 0.6s ease-in-out 0s;
}

.Service-Edit-Btn button:hover {
  background-color: rgb(0 30 255 / 15%);
  color: blue;
}

.Service-Remove-Btn button {
  background-color: rgba(255, 0, 0, 0.1);
  border: 2px solid red;
  /* padding: 5px 15px; */
  border-radius: 40px;
  color: red;
  transition: all 0.6s ease-in-out 0s;
}

.Service-Remove-Btn button:hover {
  background-color: rgba(255, 0, 0, 0.1);
  color: red;
}

/* popup Form  */

.ServiceImage-con {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ServiceImage {
  height: 40vh;
  /* border: 1px solid red; */
  width: 100%;
  border-color: #ffffff;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 5px #080808;
}

.ServiceImage img {
  height: 100%;
  width: 100%;
}

.ServiceImage label {
  position: absolute;
  top: -20px;
  right: -15px;
  /* border: 1px solid; */
  border-radius: 50%;
  /* padding: 11px; */
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  color: cornflowerblue;
}



.Service-Inputs {
  /* border: 1px solid blue; */
  margin: 20px 0px;
}

.Service-Inputs label {
  display: block;
  font-size: 17px;
  margin: 0px 0px 10px 0px;
  font-family: var(---primaryFont);
  color: gray;
}

.Service-Inputs textarea {
  min-height: 20vh !important;
}

.Service-popup-model-btn-con {
  display: flex;
  justify-content: flex-end;
}

.css-1l9ohcm {
  border-radius: 5px;
  box-shadow: 0 0 5px #808080 !important;
  border: none !important;
}


.ServiceCardImages {
  margin: 20px 0px 20px 0px;
  background-color: whitesmoke;
}

/* -----------------------add ons------------------- */
.Hospital-Head-Search-Btn-Add-btn {
  margin: 20px 0;
}
.serviceListHead {
  font-size: 30px;
  font-family: var(---primaryFont);
}
