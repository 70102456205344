.doctor_add_steps {
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 25% 75%;
}

.listCard {
  max-height: 65vh;
  overflow-x: hidden;
  position: relative;
  padding-left: 10px;
}

.devider{
  position: absolute;
  left:7px;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 1.5px;
  background-color: #afafaf;
}

.doctor_steps {
  padding: 15px;
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgb(10, 57, 101, 0.8),
    rgb(10, 57, 101)
  );
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.steps_list ul {
  padding: 0;
  margin: 0;
}

.steps_list .active span{
  background-color: red;
  transition: 0.5s ease-in-out;
  border: 2px solid red;
}

.steps_list .submited span{
  background-color: rgb(9, 211, 5);
  border: 2px solid rgb(9, 211, 5);
  transition: 0.5s ease-in-out;
}

.steps_list ul li {
  display: grid;
  grid-template-columns: 20% 80%;
  column-gap: 5px;
  margin: 20px 0;
  position: relative;
  width: 100%;
  min-height: 100px;
}

.steps_list ul li span {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 2px solid #fff;
  font-weight: 500;
  border-radius: 50px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.steps_list ul li span::before {
  content: "";
  position: absolute;
  background-color: #fff;
  height: 0px;
  width: 2px;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s ease-in-out;
}

.steps_list ul li span::after {
  content: "";
  position: absolute;
  background-color: transparent;
  height: 50px;
  border: 1px dashed #808080;
  top: 130%;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s ease-in-out;
}

.steps_list ul li.submited span::before {
  height: 50px;
  transition: 0.5s ease-in-out;
  z-index: 1;
}

.steps_list ul li:last-child span::before {
  display: none;
}

.steps_list ul li:last-child span::after {
  display: none;
}


.stepData h5 {
  color: #fff;
  font-size: min(1.6rem, 1.1rem)
}

.stepData p {
  color: #aaa;
  font-size: min(1.4rem, 1rem)
}

.doctor_add_form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
}

.doctor_add_form h3 {
  margin-bottom: 40px;
}
.account_form,
.account_form form {
  width: 100%;
}

.account_form label {
  font-weight: 600;
}

.account_form .formInput {
  padding: 10px 40px 10px 40px;
  width: 100%;
}

.input_with_icon {
  position: relative;
  display: flex;
  align-items: center;
}

.input_with_icon button {
  background-color: green;
  color: #fff;
  margin-left: 10px;
  border-radius: 5px;
  padding: 10px 15px;
  border: unset;
}
.input_with_icon svg {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: var(---primaryColour);
}

.account_form span {
  color: var(---primaryColour);
  font-size: 0.9rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.verifyIcon {
  color: green !important;
  cursor: pointer;
}

.notVerifed {
  color: red;
}

.account_form span:hover {
  font-weight: 500;
  transition: 0.3s ease-in-out;
}

.otp_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp_div button {
  background-color: var(---primaryColour);
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  border: unset;
}

.resnedOtpDoctor button {
  background-color: transparent;
  color: #111;
  text-decoration: underline;
  font-size: 0.95rem;
  border: unset;
}

.btn_group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn_group button {
  padding: 10px 25px;
  background-color: var(---primaryColour);
  color: #fff;
  border-radius: 7px;
  border: unset;
}

.sheduleList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.sheduleList select {
  flex: 1 0 170px;
  margin: 10px 0;
}

.dayInput {
  flex: 1 0 130px;
  margin: 10px;
}

.sheduleList button.submitShedule {
  flex: 1 0 30px;
  margin: 10px 0;
  background-color: green;
  color: #fff;
  border-radius: 5px;
  border: unset;
}

.startTime {
  flex: 1 0 50%;
}

.endTime {
  flex: 1 0 50%;
}

.sheludeList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.sheludeList span,
.sheludeList strong {
  flex: 1 0 25%;
  text-align: flex-start;
}

.hospitalCard {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 5px;
}

.hospitalImage {
  width: min(100px, 100%);
  margin-right: 10px;
}

.hospitalImage img {
  width: 100%;
  height: 100%;
}

.hospitalDetails h3 {
  font-size: min(1.2rem, 2rem);
  margin: unset;
}

.hospitalDetails p {
  margin: unset;
}

.buttonGroup {
  text-align: unset;
}

.buttonGroup button {
  font-size: 1rem;
}

.buttonGroup button:first-child {
  color: red;
}

.buttonGroup button:last-child {
  color: green;
}
