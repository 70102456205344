.prescriptionPage {
  padding: 18px;
  margin: 60px 0px 0 30px;
}

.prescriptionSuggestSection {
  width: 100%;
  padding: 0 20px 0 20px;
  border-right: 1.5px solid #808080;
}

.patientProfilePss {
  padding: 20px 12px;
}

.patientImgName {
  display: flex;
}

.wordSuggestionSearchBox .wrapper .search_box .search_field {
  width: 100%;
  height: 100%;
  position: relative;
}

.wordSuggestionSearchBox .wrapper .search_box .search_field .input {
  width: 100%;
  height: 100%;
  border: 0px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 38px;
  color: #6f768d;
}

.wordSuggestionSearchBox .wrapper .search_box .search_field .fas {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: #5078ef;
  cursor: pointer;
}

.wordSuggestionSearchBox ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9fa3b1;
}
.wordSuggestionSearchBox ::-moz-placeholder {
  /* Firefox 19+ */
  color: #9fa3b1;
}
.wordSuggestionSearchBox :-ms-input-placeholder {
  /* IE 10+ */
  color: #9fa3b1;
}

.wordSuggestionSearchBox .input:focus {
  border-color: unset;
  background: unset;
  box-shadow: none;
  outline: unset;
}

.expandButton:hover {
  max-width: 300px;
  color: #fff;
}

.expandButton .icon {
  font-size: 2rem;
  margin-right: 0px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
}

.expandButton .text {
  white-space: nowrap;
  padding-right: 15px;
  font-size: 1rem;
}

.wordSuggestionSearchBox .primary-btn {
  background: #4a6cf7;
  color: #fff;
  margin: 4px 4px;
}

.wordSuggestionSearchBox .primary-btn .material-symbols-outlined {
  transform: translateY(5px);
  font-size: 1.2rem;
}

.wordSuggestionSearchBox .primary-btn:focus {
  outline: 0;
  box-shadow: none;
}

.wordSuggestionSearchBox .search_field {
  position: relative;
  padding: 15px;
}
.wordSuggestionSearchBox {
  border-top: 1px solid gray;
  position: relative;
  padding: 15px 15px 15px 0px;
}

.wordSuggestionSearchBox .search_field input {
  width: 100%;
  padding-left: 0 5px;
}

.wordSuggestionSearchBox .search_field i {
  position: absolute;
  top: 26px;
  right: 26px;
  color: #aaa;
  font-size: 19px;
}

.wordSuggestionsBox{
  height: 335px;
  overflow: hidden;
  overflow-y: auto;
}

.wordSuggestionWords {
  border-top: 1px solid gray;
  /* height: 335px; */
  overflow: hidden;
  padding: 10px 0 20px 0;
}

.wordSuggestionWords span button {
  margin: 5px;
  padding: 3px 6px;
  font-size: 12px;
  text-align: left;
  border-radius: 5px;
  color: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}

.wordSuggestionWords span button.Test,
.wordSuggestionWords span button.Diagnosis {
  background-color: rgba(0, 245, 0, 0.1);
  border: 2px solid #00ce5d;
  color: #111;
}

.wordSuggestionWords span button.Instruction,
.wordSuggestionWords span button.Complaints {
  background-color: rgb(250, 233, 204);
  color: #111;
  border: 2px solid #f2b33d;
}

.wordSuggestionWords span button.Procedures,
.wordSuggestionWords span button.Findings {
  border: 2px solid #4a6cf7;
  background-color: rgb(0 30 255 / 15%);
  color: #111;
}

.wordSuggestionWords span button.drug {
  background-color: #111;
  border: 2px solid #111;
}

/* ==================== diagnosis =============================== */

.Treatment-Con {
  background-color: #fff;
}

.inputBox {
  background-color: #eee;
  margin: 25px 0px;
  border-radius: 5px;
}

.inputBoxTitle h6 {
  margin: 0;
}

.inputBoxHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  padding: 6px 15px;
  border-bottom: 1px solid;
}

.procedureTitle,
.inputBoxHead .inputBoxTitle.finding h6 {
}

.testHeading,
.inputBoxHead .inputBoxTitle.diagnosis h6 {
}

.instructionHeading span:first-child,
.inputBoxHead .inputBoxTitle.complains h6 {
  color: black;
  font-size: 1rem;
}

.instructionHeading span:last-child {
  font-size: 12px;
}
.inputBoxButton button {
  padding: 4px 6px;
  font-size: 14px;
  background-color: #fff;
  border-radius: 5px;
  color: red;
  text-decoration: underline;
  border: 2px solid #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}

.tab-pane {
  border-right: 1px solid #fff !important;
}

.inputBoxInput {
  padding: 10px;
}
.addInputButton {
  position: absolute;

  z-index: 1;
}

.addInputButton svg {
  font-size: 22px;
}

.inputBoxInput input[type="text"],
.inputBoxInput select {
  width: 100%;
  border-radius: 5px;
  border: rgb(195, 195, 195) 1px solid;
  margin: 0 5px;
}

.inputBoxInput input:active {
  border: rgb(56, 116, 159) 1px solid !important;
}

/* ======================== Prescription ======================= */

.inputBoxPrescription {
  padding: 0px;
  display: unset;
}

.inputBoxPrescription .row {
  padding: 6px;
}

.inputBoxPrescription .col-1 {
  display: flex;
  justify-content: center;
}

.addDrugsButton {
  display: flex;
}

.inputBoxHeadIcons .headIcon {
  margin: 0px 5px;
}

.inputBoxHeadIcons .headIcon svg {
}

.addDrugsButton span {
  margin-left: 15px;
  margin-top: 5px;
  font-family: var(---headFont);
  font-size: 15px;
  color: rgb(138, 138, 138);
}

.drugAllergySection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #eee;
  border-radius: 5px;
  border: solid 1px #aaa;
  margin-top: 10px;
  padding: 6px 12px;
}

.drugAllergyButtons {
  display: flex;
  align-items: center;
}

.drugAllerygyText h6 {
  margin-bottom: 0;
}

.drugAllergyButtons .drugAllergyAddIcon svg {
  position: relative;
  /* top: -10; */
  font-size: 25px;
}
.drugAllergyAddIcon {
  position: relative;
  /* top: -10px; */
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drugAllergyButtons button {
  margin: 0px 8px;
  padding: 2px 12px;
  background-color: #fff;
  border: #fff solid 2px;
  border-radius: 4px;

  color: red;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}

.prescreptionDuration {
  display: flex;
  justify-content: center;
}

.prescreptionDuration select {
  font-size: 12px;
}

.prescreptionDuration select option {
  font-size: 12px;
}
/* ============================= Summary =================================== */

.savePrintButtons {
  background-color: #eee;
  display: flex;
  padding: 10px;
}

.savePrintButtons .savePrintButton button {
  background: #4a6cf7;
  color: #fff;
  margin: 4px 4px;
  border: 1px solid #4a6cf7;
  border-radius: 5px;
  font-size: 15px;
  padding: 4px 8px;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}

.savePrintButtons .savePrintButton span {
  margin: 0px 6px;
}

.nameComplaintSection {
  padding: 12px 8px;
}

.nameComplaintSection hr {
  margin: 0px;
}

.nameComplaintSection .nameLine {
  display: flex;
  justify-content: space-between;
}

.nameLine .nameAge h6 {
  padding: 0px 5px;
}

.complaintsDiagnosisSmy li {
  list-style: decimal;
}

.testInputHead .inputTitleIcon svg,
.testSection .inputTestIcon svg,
.proceduresInputTitle .inputTitleIcon svg {
  font-size: 18px;
  margin: 0px 5px;
}

.handoutInputTitle h6 {
}

.instructionsInputBoxTitle h6 span,
.handoutInputTitle h6 span {
  font-family: var(---headFont);
  font-size: 14px;
  color: rgb(138, 138, 138);
}

.instructionsBoxSmy {
  border: 2px solid rgb(175 175 175);
  border-radius: 5px;
  padding: 6px;
  margin: 5px 0px;
}

.listOfData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
}

.listOfData::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 1.5px;
  background-color: rgb(199, 199, 199);
}
.listOfData h4 {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: unset;
}

.editButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.editButton span:first-child {
  background-color: transparent;
  border: 2px solid rgba(255, 191, 0, 0.841);
  font-size: 1.2rem;
  margin: 0 10px 0 0;
  color: rgba(255, 191, 0, 0.841);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 0 10px;
  cursor: pointer;
}

.editButton span:last-child {
  background-color: transparent;
  border: 2px solid red;
  font-size: 1.2rem;
  margin: 0 10px 0 0;
  color: red;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.checkCircleButton {
  background: transparent;
  border: 1px solid green;
  color: green;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  padding: 5px;
  border-radius: 35px;
}

.checkCircleButton:hover,
.checkCircleButton:focus {
  color: #fff;
  background: green;
  box-shadow: unset;
  border: 1px solid green;
}

.inputDetail {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(60%, -50%);
}

.inputDetail .input-style-1 {
  margin: 0 0px;
}

.inputDetail .input-style-1 input {
  max-width: 80px;
}

/* ================= summary side box ====================== */

/* follow ups */
.accordion-button .accordianButtonHead span {
  margin: 0px 5px;
}

.summaryAccordianBox .followDaysButtons,
.summaryAccordianBox .followWeekButtons {
  display: flex;
  justify-content: space-evenly;
}

.summaryAccordianBox .followDayButton button {
  background: #fff;
  border: #4a6cf7 solid 2px;
  color: #4a6cf7;
  padding: 3px 12px !important;
  border-radius: 5px;
}

.followDaysButtons .followDayText h6 {
  color: #4a6cf7;
  margin-bottom: 0;
}

.followDaysButtons .followDayResetBtn button,
.followWeekButtons .followweekButton button {
  background: #fff;
  border: #4a6cf7 solid 2px;
  color: #4a6cf7;
  padding: 3px 6px !important;
  border-radius: 5px;
}

.followTimeButton button {
  background: #fff;
  border: #eee solid 2px;
  padding: 3px 6px !important;
  border-radius: 5px;
}

.followTimeButton button span {
  margin: 0px 5px;
}

.followInputBox textarea {
  background: rgba(239, 239, 239, 0.5);
}

/* Reference  */

.referencesSearchBox {
  margin-bottom: 12px;
}

.referencesAccordian .referenceAddButton button {
  background: #4a6cf7;
  color: #fff;
  padding: 3px 8px;
  border: solid 1px #4a6cf7;
  border-radius: 5px;
}

.referencesList ul li p {
  margin-bottom: 0;
  padding: 2px;
}

.referencesList ul span input[type="checkbox"] {
  width: 20px;
}

.name {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.updateInput:focus,
.updateInput:hover,
.updateInput:focus-within,
.updateInput {
  width: 100%;
  border: unset !important;
  box-shadow: unset !important;
}

.updateInput:disabled {
  background-color: transparent;
}

.updateInputActive:focus,
.updateInputActive:hover,
.updateInputActive:focus-within,
.updateInputActive {
  width: 100% !important;
  border: 2px solid #111 !important;
}

.dropdown-menu {
  min-width: 100% !important;
}

.dropdown-toggle {
  background: transparent !important;
  color: #111 !important;
  border: unset !important;
  padding: 0 5px !important;
}

.dropdown-toggle:focus,
.dropdown-toggle:hover {
  box-shadow: unset !important;
  border: unset !important;
  background-color: transparent !important;
}

.dropdown-toggle::after {
  display: none;
}

.selectedList .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}
.selectedList .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 10px 10px 0 0 !important;
}

.selectedList .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  visibility: hidden !important;
  width: 0px !important;
  padding: 0 !important;
}
.selectedList .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: unset !important;
}
.selectedList .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  visibility: hidden !important;
}

.selectedList .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  margin: unset !important;
}

.selectedList h6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.location {
  padding: 0 10px;
  font-size: 1.4rem;
}

.dateSelect {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.calenderPopup {
  position: absolute;
  z-index: 1;
  top: 35px;
  left: 0;
}

.calenderPopup.procedure {
  bottom: 35px;
  top: unset;
}

.dateSelect span {
  padding: 5px 0 0 0;
}

.adviceButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  border: 1px solid #808080;
  padding: 5px;
  border-radius: 5px;
}

.adviceButton span {
  margin: 0 5px;
  border: 1px solid #808080;
  padding: 3px 10px;
  border-radius: 4px;
  background: #eee;
  color: #111;
  font-weight: 500;
  cursor: pointer;
}

/* ======================= Shedule Modl ======================= */

.inputBoxInoutInnerDiv .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  transform: translate(14px, 13px) scale(1);
  font-size: 0.85rem;
}
.inputBoxInput
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.inputBoxInput .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.inputBoxInput .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 5px !important;
  font-size: 0.89rem;
}

.inputBoxInput .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 0 10px 0 3px !important;
}

.addedDayTimeBox h6 {
  text-transform: uppercase;
}

@media print {
  * {
    box-sizing: border-box;
  }

  body {
    font-size: 12px;
    line-height: 1.5;
    color: #333;
    margin: 0;
    padding: 0;
  }

  #domElWithout {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    line-height: 1.5;
    color: #333;
  }
}

.historyDetail {
  margin: 10px 0;
  border: 1px solid #808080;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;
  background-color: white;
  border-radius: 5px;
  text-align: justify;
}

.historyTitle {
  color: var(--bs-red);
  font-weight: 500;
  font-size: 1rem;
}

.generateCertificate .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 10px 14px 10px 0 !important;
}

.generateCertificate .accordion-button:not(.collapsed) {
  color: #111 !important;
  background-color: unset !important;
  box-shadow: unset !important;
}
.generateCertificate .accordion-item {
  border: unset !important;
}

.generateCertificate .accordion-button::after {
  visibility: hidden;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: rgba(0, 0, 0, 0.6) !important;
}

.addAppointmentForm
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  top: -5px !important;
}
.addAppointmentForm .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: unset !important;
  padding: 0 10px;
}

.prescriptionSuggestSummary {
  overflow-y: scroll;
  max-height: 600px;
  padding-bottom: 50px;
}

.prescriptionSuggestSummary::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.prescriptionSuggestSummary::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.prescriptionSuggestSummary::-webkit-scrollbar-thumb {
  background: #eee;
}

/* Handle on hover */
.prescriptionSuggestSummary::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

.css-1anqmj6-MuiPopper-root-MuiPickersPopper-root {
  z-index: 13000;
}

.patientName {
  padding-left: 10px;
}
.bill {
  margin-left: 25px;
}
.bill svg {
  font-size: 30px;

  margin-left: 20;
  margin-top: 2px;
}
.billtitle {
  margin-left: 20px;
  margin-top: 5px;
}
.billinput {
  height: 37px;
  border: 1px solid gray;
  border-radius: 25px;
  padding: 3px;
  width: 189px;
}
.tabssec {
  padding: 15px;
}
/* .Facilities-Section2-tabpanel {
  padding: 0px 25px 0 0;
} */

.billbuttion {
  margin-left: 35px;
}

.inputpadding{
  position: relative;
}
.iconadde {
  position: absolute;
  left: 50px;
  top: 50%;
  z-index: 1;
}
.green {
  background-color: green;

  border-radius: 25px;
  height: 10px;
  width: 10px;
  margin: 6px;
}
.orange {
  background-color: orange;

  border-radius: 25px;
  height: 10px;
  width: 10px;
  margin: 6px;
}
.blue {
  background-color: blue;

  border-radius: 25px;
  height: 10px;
  width: 10px;
  margin: 6px;
}
/* .inputBoxHead{
    align-items: center;
    display: flex;
} */
.inputBoxTitle {
  display: flex;
  text-align: center;
}
.findingtext {
  display: flex;
}
.inputpadding input {
  padding: 7px 7px 7px 46px;
}
.inputspa input {
  padding: 7px 0px;
}
.inputselect select {
  padding: 7px 0px;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  border-radius: 50px !important;
  color: white !important;
  background-color: var(---primaryColour) !important;
  font-size: 16px !important;
  padding: 5px 18px !important;
  text-transform: capitalize !important;
  margin: 0px 15px 15px 0px !important;
  border: none !important;
  border: 2px solid var(---primaryColour) !important;
  transition: all 0.6s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testHeading {
  display: flex;
  align-items: center;
}
.instructionHeading {
  display: flex;
  align-items: center;
}
.procedureTitle {
  display: flex;
  align-items: center;
}
.paddbot {
  padding-bottom: 15px;
}
.paddin5 {
  padding: 5px;
}
@media (max-width: 992px) {
  .sidebar1 {
    display: none !important;
  }
  .prescriptionSuggestSection {
    position: initial;
  }
  .wordSuggestionSearchBox {
    border-top: none;
  }
  .diagnosisFromSection {
    border-left: none;
  }

}
@media (min-width: 992px) {

  .sidebar2 {
    display: none !important ;
  }
}
.border1pxgray {
  border-bottom: 1px solid gray;
}
.fillsection {
  z-index: 100;
  width: 100%;
  max-width: 300px;
  position: absolute;
  overflow: scroll;
  max-height: 110px;
  margin-left: 54px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 0 4px #808080;
  background-color: white;
}
.fillsection ul {
  padding: 5px;
}
.fillsection ul li {
  border-bottom: 1px solid #808080;

  padding: 5px 10px 5px 10px;
}
.fillsection ul li:hover {
  background-color: #eee;
}

/* ============================ Handout Details ======================== */

.handoutDetail {
  border-top: 1.59px solid #808080;
  padding: 10px 0 0px 0;
}

.handoutFavouriteList {
  padding: 20px 0;
}

.handoutFavouriteList span {
  padding: 5px 7px;
  box-shadow: 0 0 5px #808080;
  border-radius: 5px;
  font-weight: 500;
  font-size: 0.85rem;
  margin-right: 10px;
}

.handoutFavouriteList span svg {
  color: gold;
}

@media (max-width: 767px) {
  .inputpadding input {
    padding: 7px;
  }
  .prescriptionSuggestSection {
    border: unset;
  }
  .patientProfilePss {
    padding: 0 0 10px 0px;
  }
  .prescriptionPag {
    padding: 0 10px;
  }
  .calenderPopup {
    position: absolute;
    z-index: 1;
    top: 35px;
    left: 50%;
    transform: translateX(-70%);
  }
  .react-calendar {
    width: 260px !important;
  }
}

@media (max-width: 576px) {
  .listOfData {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 20px;
    position: relative;
    flex-direction: column;
  }
}
