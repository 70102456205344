.complaintBoxContent {
    padding: 5px;
}

.complaintBoxHead h6 {
    background-color: #eee;
    padding: 10px;
}

.complaintTypeBox input[type="text"] {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: rgb(195, 195, 195) 1px solid;
    margin: 0 5px;
}

.complaintNameText .complaintNameInput p {
    padding: 5px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
} 

.addedType {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
}

.addedType .deleteIcon {
    color: red;
}

.buttonSection{
    position: absolute;
    bottom: 30px;
    right: 20px;
}

.buttonSection button:last-child{
    background: transparent;
    color: #111;
    margin: 0 5px;
    border: unset;
    transition: 0.3s ease-in-out;
    font-size: 1.1rem;
}


.buttonSection button:first-child{
    background: transparent;
    color: red;
    margin: 0 5px;
    border: unset;
    transition: 0.3s ease-in-out;
    font-size: 1.1rem;
}

.buttonSection button:last-child:hover{
    color: #fff;
    background-color: #111;
}

.buttonSection button:first-child:hover{
    color: #fff;
    background-color: red;
}