.prescriptionHead {
  display: flex;
  justify-content: space-between;
  padding: 12px 15px 8px 15px;
  border-bottom: 1px solid #888;
}

.prescriptionHead .prescriptionHeadBtn button {
  background: #4a6cf7;
  color: #fff;
  margin: 4px 4px;
  border: 1px solid #4a6cf7;
  border-radius: 5px;
  font-size: 17px;
  padding: 8px 10px;
}

.templateSettingSection {
  padding: 10px 10px 10px 20px;
}

.settingSelectClinic .inputSelectClinic select,
.settingSelectFont select {
  width: 100%;
  border-radius: 20px;
  padding: 8px 15px;
  border: 1px solid #e5e5e5;
}

.settingSelectFont {
  padding: 8px 0px;
}

.settingSelectFont .resetFontBtn button {
  background: #fff;
  color: #4a6cf7;
  margin: 4px 4px;
  border: 1px solid #4a6cf7;
  border-radius: 5px;
  font-size: 14px;
  padding: 4px 6px;
}

.settingFontSize input {
  width: 100%;
  border-radius: 20px;
  padding: 8px 15px;
  border: 1px solid #e5e5e5;
  text-align: center;
}

.pageSettingHead h5,
.generationSettingsHead h5,
.pageOrientationHead h5,
.letterHeadTitle h5 {
  margin: 20px 0px;
  color: var(---primaryColour);
}

.pageSettingOptions .input-style-1 input,
.generationSettingOptions .input-style-1 input,
.pageOrientationSection .degreeInput input,
.letterHeadInput input {
  width: 100%;
  border-radius: 8px;
  padding: 6px 10px;
  border: 1px solid #e5e5e5;
  text-align: center;
  margin-bottom: 15px;
}

.pageOrientationHead {
  display: flex;
  justify-content: space-between;
}

.pageOrientationHead input {
  margin-top: 20px;
}

.pageOrientationSection .degreeInput label {
  font-size: 14px;
}

.letterHeadSection .letterHeadImg {
  width: 180px;
  margin-bottom: 20px;
}

.letterHeadSection .letterHeadImg img {
  width: 100%;
  height: 100%;
}

/* =================== Template View ============================ */

.templateRadioBtns {
  padding: 10px 5px;
}

.templateRadioBtns label {
  margin-right: 18px;
}

.templateViewSection {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 10px;
}

.templateViewBgImg {
  /* background: linear-gradient(to right, rgb(255,255,255, .1), rgb(255,255,255, .1)),url(../Images/letter-head.jpg); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 5px;
  width: 21cm;
  min-height: 29.7cm;
}

/* ================= template 2 ======================== */

.tempTwoBasicInfo {
  padding: 10px 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
}

.tempTwoBasicInfo .basicListItem h6 {
  font-weight: bold;
}

.tempTwoBasicInfo .basicListItem h6 span {
  font-weight: normal;
  margin-left: 6px;
}

.tempTwoVitals {
  padding: 0px 10px;
}

.tempTwoVitals .vitalItem h6 {
  text-align: center;
}

.tempTwoVitals .vitalItem p {
  text-align: center;
  margin-bottom: 0px;
}

.tempTwoVitals .col {
  border: 1px solid #888;
  padding: 5px;
}

.tempTwoDiagnosis {
  margin: 10px 0px;
  padding: 8px;
}

.tempTwoDiagnosis .diagnosisItem h6 {
  font-weight: bold;
  margin-bottom: 12px;
}

.tempTwoDiagnosis .diagnosisItem h6 span {
  font-weight: lighter;
  margin-left: 6px;
}

.drugsHeadSmy h6 span {
  font-size: 13px;
}

.drugInstructionTempTwo .instructions p {
  margin-bottom: 0px;
}

.doctorSignature {
  margin-top: 15px;
}

.doctorSignature .doctorQr {
  margin: 0px 15px;
}

.doctorSignature .qrImg {
  width: 100px;
  height: 100px;
}

.doctorSignature .qrImg img {
  width: 100%;
  height: 100%;
}

.doctorSignatueDetails .signImg {
  width: 110px;
  height: 65px;
}

.doctorSignatueDetails .signImg img {
  width: 100%;
  height: 100%;
}

/* ///////////////////// */

.PriscriptionTemplates-Priscription {
  position: relative;
}

.Priscriptionicon-con {
  position: fixed;
  top: 50%;
  transform: translateY(50%);
  right: 1vh;
  border: 1px solid #111;
  z-index: 10000;
  background-color: rgb(0 0 0 / 15%);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.Priscriptioniconedit {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  /* border: 1px solid red; */
  background-color: transparent;
  color: #111;
  border: 1px solid transparent;
  transition: all 0.6s ease-in-out 0s;
  margin: 0px 0px;
}

.Priscriptionicon-con svg {
  font-size: 1.4rem;
  animation: rotation 3s infinite linear;
}

.Side-Info {
  display: none;
  position: fixed;
  width: 150px;
  left: -150px;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 15px 0;
  transition: left 1s ease-in 0.5s;
  border: 1px solid red;
}

.Priscriptionicon-con {
  display: none;
}


.inputSelectClinic select {
  background-color: whitesmoke;
}

#templatFont {
  background-color: whitesmoke;
}

.PrescriptionInputFSize {
  background-color: whitesmoke;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 992px) {
  .PriscriptionTemplates-Info {
    display: none;
  }

  .Side-Info {
    display: block;
  }

  .Priscriptionicon-con {
    display: block;
  }
}

/* ///////////////////////// */
