
.attributesHead {
    display: flex;
    justify-content: space-between;
    padding: 8px 15px;
}

.selectClinicSection { 
 padding: 15px 20px;
 margin: 5px 10px;
 box-shadow: rgb(0 0 0 / 20%) 0px 1px 5px;
 border-radius: 10px;
}

.selectClinicSection select {
   width: 100%;
    border-radius: 20px;
    padding: 8px 15px;
    border: 1px solid #e5e5e5;
}

.attributesHeadButton button {
    background: #4a6cf7;
    color: #fff;
    margin: 4px 4px;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    font-size: 17px;
    padding: 8px 10px;
}

.attributesSection {
    padding: 4px 20px;
}

.attributesSection .attributesHead p {
    font-size: 22px;
} 

.attributesList .input-attribute {
    display: flex;
    align-items: center;
    padding: 15px 0px;
}

.attributesList .input-attribute input {
    width: 18px;
    height: 18px;
}

.attributesList .input-attribute label {
    margin-left: 6px;
}