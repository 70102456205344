.appointmentHead{
    padding: 20px 15px 0 15px;
    position: relative;
}

.appointmentHead button{
    width: 30px;
    height: 30px;
    background-color: transparent;
    color: rgb(255, 22, 22);
    border-radius: 50px;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;
    position: absolute;
    right: 20px;
    top: 20px;
}

.appointmentDrawerTitle h5{
    color: var(---primaryColour);
    font-weight: 500;
    font-size: 1.3rem;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 10px;
}

.appointmentDrawerTitle p{
    color: #808080;
    font-size: .9rem;
}

.appointmentDetainInCount {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.appointmentListData{
    padding: 10px 15px;
    margin-top: 10px;
    overflow: hidden;
    overflow-y: scroll;
    max-height: 83vh;
}

.appointmentListCard {
  box-shadow: 0 0 3px #808080;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in background-color;
}
.appointmentListCard.visit{
    background-color: #eee;
}

.appointmentListCard:hover{
    background-color: #eee;
}

.userDetails{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.userInfo{
    margin-left: 15px;
    margin-bottom: 5px;
}

.userInfo h5{
    font-size: 1rem;
    margin: 0 0 5px 0;
    font-weight: 500;
    color: #111;
}

.userInfo p{
    margin: 0;
    font-size: .85rem;
}

.appoinment_details{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.appoinment_details h5{
    margin: 0;
    font-size: .85rem;
    color: #444;
}


