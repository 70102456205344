
.addAllergyHead {
    text-align: center;
    padding: 10px;
    background-color: #eee;
    margin-bottom: 20px;
}

.addAllergyHead h6 {
    margin-bottom: 0;
}

.addAllergyBody input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: rgb(195, 195, 195) 1px solid;
    margin: 0 5px;
}