.login-page {
  background: url("https://wallpaperaccess.com/full/846241.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content form {
  width: 360px;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  margin: auto;
}

.login-content h4 {
  margin: 15px 0;
  color: #444;
  text-transform: capitalize;
  font-size: 2.2em;
}


.liginSubmitBtn {
  display: block;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  outline: none;
  border: none;
  background-image: linear-gradient(to right, #507292, #0a3863, #507292);
  background-size: 200%;
  font-size: 1.2rem;
  color: #fff !important;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 1rem 0;
  cursor: pointer;
  transition: 0.5s;
}
.liginSubmitBtn:hover {
  background-position: right;
  color: #fff;
}
