.doctorView .CategoryCard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  align-items: flex-start;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 5px #808080;
  margin: 10px 0;
  padding: 10px;
  background: #fff;
}

.doctorView .CategoryCard .categImg {
  width: 100px;
  height: auto;
  margin: auto;
  padding: 5px 0;
}

.doctorView .CategoryCard .categImg img {
  width: 100%;
  height: 100%;
}

.doctorView .CategoryCard .categText {
  display: flex;
  justify-content: left;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin: 6px 16px;
}

.CategoryCard .categButton {
  position: absolute;
  right: 50px;
  bottom: 30%;
  display: flex;
  align-items: center;
} 

.CategoryCard .categButton .editBtn svg {
  font-size: 35px;
    margin: 0px 10px;
    background: blue;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
}

/* .CategoryCard .categButton .deleteBtn svg {
  font-size: 35px;
    margin: 0px 10px;
    background: red;
    padding: 5px;
    border-radius: 50%;
    color: #fff;
} */


.doctorView .CategoryCard .categText h2 {
  text-align: left;
  padding: 0px 0;
  color: #111;
  font-weight: 600;
  text-transform: capitalize;
  position: relative;
  font-family: var(--fontPopins);
  font-size: 1rem;
}
/* 
.doctorView .header-search form {
  max-width: 270px;
  position: relative;
}
.doctorView .header-search form input {
  width: 100%;
  border: 1px solid #efefef;
  background: rgba(239, 239, 239, 0.5);
  border-radius: 10px;
  height: 46px;
  padding-left: 44px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
} */

/* .doctorView .wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.doctorView .wrapper .search_box {
  background: #fff;
  border-radius: 6px;
  height: 50px;
  border: 1px solid;
  border-color: #b3c6ff;
  display: flex;
  padding: 0px;
  box-shadow: 0 8px 6px -10px #b3c6ff;
}

.doctorView .wrapper .search_box .dropdown {
  width: 164px;
  border-right: 2px solid #dde2f1;
  color: #9fa3b1;
  position: relative;
  cursor: pointer;
}

.doctorView .wrapper .search_box .dropdown .default_option {
  text-transform: uppercase;
  padding: 15px 15px;
  font-size: 14px;
}

.doctorView .wrapper .search_box .dropdown ul {
  position: absolute;
  top: 70px;
  left: -10px;
  background: #fff;
  width: 150px;
  border-radius: 5px;
  padding: 20px;
  overflow-y: scroll;
  height: 250px;
  z-index: 100;
  display: none;
  box-shadow: 8px 8px 6px -10px #b3c6ff;
}

.doctorView .wrapper .search_box .dropdown ul.active {
  display: block;
}

.doctorView .wrapper .search_box .dropdown ul li {
  padding-bottom: 10px;
  font-size: 0.9rem;
}

.doctorView .wrapper .search_box .dropdown ul li:last-child {
  padding-bottom: 0;
}

.doctorView .wrapper .search_box .dropdown ul li:hover {
  color: #6f768d;
}

.doctorView .wrapper .search_box .dropdown:before {
  content: "";
  position: absolute;
  top: 22px;
  right: 20px;
  border: 8px solid;
  border-color: #5078ef transparent transparent transparent;
}

.doctorView .wrapper .search_box .search_field {
  width: 100%;
  height: 100%;
  position: relative;
}

.doctorView .wrapper .search_box .search_field .input {
  width: 100%;
  height: 100%;
  border: 0px;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 38px;
  color: #6f768d;
}

.doctorView .wrapper .search_box .search_field .fas {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 22px;
  color: #5078ef;
  cursor: pointer;
}

.doctorView ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9fa3b1;
}
.doctorView ::-moz-placeholder {
  /* Firefox 19+ */
  color: #9fa3b1;
}
.doctorView :-ms-input-placeholder {
  /* IE 10+ */
  color: #9fa3b1;
}

.doctorView .input:focus {
  border-color: unset;
  background: unset;
  box-shadow: none;
  outline: unset;
}

.expandButton {
  position: fixed;
  bottom: 20%;
  right: 0;
  background-color: #099;
  color: white;
  text-decoration: none;
  border-radius: 60px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  width: auto;
  max-width: 46px;
  -webkit-transition: max-width 0.5s;
  transition: max-width 0.5s;
}

.expandButton:hover {
  max-width: 300px;
  color: #fff;
}

.expandButton .icon {
  font-size: 2rem;
  margin-right: 0px;
  padding: 0px 8px;
  display: flex;
  align-items: center;
}

.expandButton .text {
  white-space: nowrap;
  padding-right: 15px;
  font-size: 1rem;
}

.doctorView .primary-btn {
  background: #4a6cf7;
  color: #fff;
  margin: 4px 4px;
}

.doctorView .primary-btn .material-symbols-outlined {
  transform: translateY(5px);
  font-size: 1.2rem;
}

.doctorView .primary-btn:focus {
  outline: 0;
  box-shadow: none;
}


/* ================= Hospiral schedule button =================== */

.AddScheduleHead h6 {
  text-align: center;
}

.CategoryCard .categButton .scheduleBtn button {
  border: none;
}

/* .CategoryCard .categButton .scheduleBtn svg {
  color: #fff;
  margin: 0px 10px;
  background: #099;
  padding: 5px;
  font-size: 35px;
} */

.addDayTime .inputBoxHead {
  display: block;
}

.addedDayTimeInner {
  padding: 5px 0px ;
}

/* .addedDayTimeInner::after {
  content: '';
  height: 1px;
  background-color: #111;
  width: 95%;
  overflow: hidden;
  margin: auto;
} */

.addedDayTimeInner .addedDayTimeDeleteIcon svg {
  color: #da0e0e;
} 

/* 
.addDayTime .inputboxHeadInner,
.addedDayTimeInner   {
  display: flex;
  justify-content: space-around;
}

.addedDayTimeInner .addedDayTimeBox {
  text-align: center;
}

.inputBoxInput .inputBoxInoutInnerDiv {
  margin: 0px 5px;
} */

.inputBoxInoutInnerDiv button {
  border: none;
  background: none;
}

.inputBoxInoutInnerDiv button svg {
  font-size: 25px;
  color: rgb(125, 205, 6);
}

.Search .MuiInputBase-input{
  padding: 10px 10px !important;
}


.HospitalSBtn {
  border-radius: 50px !important;
  color: white !important;
  background-color: var(---primaryColour) !important;
  font-size: 16px !important;
  padding: 3px 18px 5px 18px !important;
  text-transform: capitalize !important;
  border: none !important;
  border: 2px solid var(---primaryColour) !important;
  transition: all .6s ease-in-out 0s;
}

.HospitalSBtn:hover {
  color: var(---primaryColour) !important;
  background: white !important;
  border: 2px solid var(---primaryColour) !important;
}


.Hospital-Head-Search-Btn-Add-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}
