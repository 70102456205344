.patientsListTable {
    padding: 40px;
}

.patientsListTable table {
    background-color: #fff;
    padding: 15px;
    box-shadow: 0px 10px 20px rgb(200 208 216 / 50%);
    border-radius: 6px;
}

.patientListHead {
    margin-top: 20px;
    padding: 0px 30px ;
    display: flex;
   
    justify-content: space-between;
}

.patientListHead .addPatientBtn button{
    background: #4a6cf7;
    color: #fff;
    margin: 4px 4px;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    font-size: 17px;
    padding: 8px 10px;
}


.patientImg {
    width: 50px;
    height: 50px;
}

.patientImg img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.patientsListTable table td,
.patientsListTable table th {
    text-align: center;
}

.patientsListTable .deleteIcon svg   {
    color: #d70000;
}
 
.patientsListTable .editIcon svg   {
    color: #0000ca;
}

.patientsListTable .tableIcon svg{
    font-size: 20px;
}

.patientsListTable .patientTypeBox{
    border-radius: 10px;
    padding: 6px;
    color: #fff;
    font-weight: bold;
}

.patientsListTable .favouriteClass {
color: darkorange;
background-color: transparent;
display: flex;
justify-content: center;
align-items: center;
}

.patientsListTable .notoriousClass {
    background-color: #d70000;
}

.patientsListTable .surgicalClass {
    background-color: rgb(242, 214, 0);
}
/* -------------------card css----------------------- */
/* Utilities */
.card::after,
.card img {
  border-radius: 50%;
}


.stats {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  padding:10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, .5);
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
  margin: 10px 0;
  position: relative;
  transform-style: preserve-3d;

}
/* .card::before,
.card::after {
  content: '';
  position: absolute;
  z-index: -1;
} */
/* .card::before {
  width: 100%;
  height: 100%;
  border: 1px solid #FFF;
  border-radius: 10px;
  top: -.7rem;
  left: -.7rem;
} */
/* .card::after {
  height: 15rem;
  width: 15rem;
  background-color: #4172f5aa;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #FFF
} */
/* .profileSection .cardBox
{
    display:flex;
  
} */
.cardBox{
  width: 3rem;
  /* min-width: 80px; */
  height:3rem;
  /* min-height:80px; */
  box-shadow: 0 0 0 5px #FFF;
 
}
.cardBox img{
  width:100%;
  height:100%;
}

/* .infos {
  margin-left: 1.5rem;
} */

.Infos .Infoname {
  margin: 10px;
  text-align:left;
  padding-left:20px; 
}
.Infoname  h2 {
  font-size: 1rem;
  font-family:var(---primaryFont);
  text-transform: capitalize;
  margin:unset;
}
.Infoname  h4 {
  font-size: .8rem;
  color: #333
}



.text {
  font-size: .9rem;
  line-height:10px !important;
  margin-left:10px;
  /* margin-bottom: 1rem; */
}

.stats {
    
  margin-bottom: 1rem;
}
.stats li {
  min-width: 5rem;
}
.stats li h3 {
  font-size: .99rem;
}
.stats li h4 {
  font-size: .75rem;
}

.links button {
  font-family: 'Poppins', sans-serif;
  min-width: 120px;
  padding: .5rem;
  border: 1px solid #222;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all .25s linear;
}
.links .follow,
.links .view:hover {
  background-color: #222;
  color: #FFF;
}
.links .view,
.links .follow:hover{
  background-color: transparent;
  color: #222;
}

@media screen and (max-width: 450px) {
  .card {
    display: block;
  }
  .infos {
    margin-left: 0;
    margin-top: 1.5rem;
  }
  .links button {
    min-width: 100px;
  }
}
