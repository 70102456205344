.appointmentsHead h2 {
  font-family: var(---headFont);
  text-align: center;
  margin: 20px 0px;
}

.searchBoxes {
  padding: 10px 20px;
}

.searchBoxes .searchBox label {
  font-size: 14px;
  font-weight: 500;
  color: #262d3f;
}

.searchBoxes .searchBox button {
  margin-top: 20px;
  background: #4a6cf7;
  color: #fff;
  border: 1px solid #4a6cf7;
  border-radius: 5px;
  font-size: 17px;
  padding: 8px 10px;
}

.searchBoxes .searchBox {
  padding: 0px 10px;
  position: relative;
}

.searchBox .searchBoxIcon {
  position: absolute;
  right: 25px;
  top: 8px;
}

.searchBox .searchBoxIcon svg {
  font-weight: bold;
  font-size: 25px;
}

.searchBoxes .searchBox input,
.searchBoxes .searchBox select {
  width: 100%;
  border-radius: 20px;
  padding: 8px 15px;
  border: 1px solid #e5e5e5;
}

/* ================== Calendar Bar ======================== */

.calendarBar {
  margin: 5px 15px;
  padding: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 5px;
  border-radius: 10px;
}

.calendarBarSlots .calnedarSlotBox span {
  border: 1px solid #afafaf;
  padding: 6px 10px;
  margin: 0px 5px;
  border-radius: 5px;
}

/* =============== appointment boxes ==================== */

.appointmentBoxes {
  padding: 12px 20px;
}

/* .appointmentBox {
  width: 100%;
  padding: 10px;
  box-shadow: 0 1px 4px #c3c3c3;
  margin-bottom: 18px;
  border-radius: 5px;
} */

.appointmentBox p {
  margin-bottom: 0;
}

.appointmentBoxHead h6 {
  font-size: 18px;
}

.appointmentBox .appointmentBoxHead {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.appointmentBoxHead h6 {
  margin-bottom: 0px;
}

.appointmentBoxHead .headName {
  margin-bottom: 10px;
}

.appointmentBoxIcon svg {
  padding: 0px 4px;
  font-size: 28px;
  color: #5d5d5d;
}

.appointmentBoxIcon svg:hover {
  color: #4a6cf7;
}

.appointmentBoxText {
  padding-left: 12px;
}

/* ===================== Appointment Calender ====================== */

.appointmentCalender {
  position: relative;
}

/* ==================== Appointment Detail Box ====================== */

.appointmentDetailBox {
  position: absolute;
  height: 92vh;
  background-color: #fff;
  z-index: 1000;
  top: 60px;
  right: 0;
  padding: 20px;
  box-shadow: 0 1px 4px rgba(216, 216, 216, 0.933);
  opacity: 0;
}

.appointmentDetailBoxAppear {
  opacity: 1;
}

.AddAppointmentSidebar {
  opacity: 0;
}

.patientProfileApb {
  padding: 20px;
  width: 100%;
  max-height: 180px;
  height: 100%;
  border-radius: 50%;
}

.patientProfileApb img {
  width: 100%;
  height: 100%;
  box-shadow: rgb(0 0 0 / 20%) 0px 4px 8px;
  /* border-radius: 50%; */
}

.PatientDetailAdb h4 {
  text-align: center;
}

.moreDetailAdb h6 span {
  padding: 0px 8px;
}

.PatientDetailAdb h6 span {
  padding: 0px 8px;
}

.buttonsAdb {
  display: flex;
  justify-content: flex-end;
}

.buttonsAdb svg {
  font-size: 22px;
  margin: 0px 5px;
}

.buttonsAdb .adbEdit svg {
  color: #4a6cf7;
}

.buttonsAdb .deleteAdb {
  color: red;
}

/* =================== Add Appointment Sidebar ==================== */

.AddAppointmentSidebar input {
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.AddAppointmentSidebar select {
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.bookAppointmentBtn {
  display: flex;
  justify-content: flex-end;
}

.bookAppointmentBtn button {
  background: #4a6cf7;
  color: #fff;
  padding: 6px 8px;
  border: #4a6cf7 solid 1px;
  border-radius: 5px;
}

.AddAppointmentSidebarHead h6 {
  text-align: center;
}

/* =============== appointment boxes ==================== */

.appointmentBoxesHead {
  padding: 10px 20px;
  margin: 0px 20px;
  display: flex;
  justify-content: flex-end;
  background-color: #eee;
}

.appointmentBoxesHead button {
  width: 100px;
  margin: 0px 5px;
  background: #4a6cf7;
  color: #fff;
  border: 1px solid #4a6cf7;
  border-radius: 5px;
  font-size: 14px;
  padding: 8px 10px;
}

.appointmentBoxes {
  background-color: #fff;
  box-shadow: 0px 10px 20px rgb(200 208 216 / 50%);
  border-radius: 6px;
  margin: 0px 20px;
  padding: 20px;
}

/* appointment card  */

.appointmentBoxes p {
  margin-bottom: 0;
}

.appointmentBoxes .appointmentBox {
  margin: 0px 5px 10px 5px;
  /* border: 1px solid #b4b3b3; */
}

.appointmentBoxes .appointmentBox .appointmentBoxHead {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #eee;
  padding: 12px;
  border-bottom: 1px solid #b4b3b3;
}

.appointmentBoxes .appointmentBox .appointmentBoxHead p {
  margin-bottom: 0;
}

.appointmentBoxes .appointmentBox .appointmentBoxHead svg {
  color: #4a6cf7;
  font-weight: bold;
}

.appointmentBoxTime {
  margin-top: 10px;
}

.appointmentBoxTime svg {
  font-size: 22px;
  margin: 0px 5px;
}

.appointmentBoxTime .appointmentStepIcon {
  cursor: pointer;
}

.appointmentBoxTime .appointmentStepIcon svg {
  background-color: #4a6cf7;
  color: #fff;
  border-radius: 50%;
  padding: 5px;
  font-size: 28px;
}

.appointmentBoxes .appointmentBox .appoinmentBoxText {
  padding: 8px;
  /* height: 135px; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.appointmentBoxes .appointmentBox .appoinmentBoxText p {
  margin-bottom: 0;
  color: #111;
  padding: 2px 0px;
  /* text-align: center; */
}

.appointmentBoxes .appointmentBox .appoinmentBoxText span svg {
  font-size: 20px;
  margin: 0px 5px;
}

.appointmentBoxes .appointmentBox .appointmentBoxFooter {
  width: 100%;
  background-color: #eee;
  padding: 12px;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid #b4b3b3;
}

.appointmentBoxes .appointmentBox .appointmentBoxFooter p {
  font-weight: bold;
}

.appointmentBoxes .appointmentBox .appointmentBoxFooter svg {
  color: #4a6cf7;
  font-weight: bold;
}

/* =============== card Animations ====================== */

.appointmentBox .card {
  --background: #ffffff;
  --text: black;
  position: relative;
  height: 12rem;
  box-shadow: rgb(0 0 0 / 20%) 0px 1px 5px;
}

.appointmentBox .card .multi-button {
  z-index: 0;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  border-radius: 100%;
  width: 0rem;
  height: 0rem;
  transform: translate(-50%, -50%);
  transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
}

.appointmentBox .card .multi-button button {
  display: grid;
  place-items: center;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: none;
  border-radius: 100%;
  background: var(--background);
  border: 1px solid #111;
  color: #111;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: 0.25s cubic-bezier(0.25, 0, 0, 1);
  box-shadow: 0 0 0rem -0.25rem var(--background);
}

.appointmentBox .card .multi-button button:hover {
  background: var(--text);
  color: var(--background);
  box-shadow: 0 0 1rem -0.25rem var(--background);
}

.appointmentBox .card .multi-button button:first-child:nth-last-child(1) {
  left: 25%;
  top: 25%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(2):nth-child(1),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(2)
  ~ *:nth-child(1) {
  left: 37.5%;
  top: 18.75%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(2):nth-child(2),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(2)
  ~ *:nth-child(2) {
  left: 18.75%;
  top: 37.5%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(3):nth-child(1),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(3)
  ~ *:nth-child(1) {
  left: 50%;
  top: 15.625%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(3):nth-child(2),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(3)
  ~ *:nth-child(2) {
  left: 25%;
  top: 25%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(3):nth-child(3),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(3)
  ~ *:nth-child(3) {
  left: 15.625%;
  top: 50%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4):nth-child(1),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4)
  ~ *:nth-child(1) {
  left: 62.5%;
  top: 18.75%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4):nth-child(2),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4)
  ~ *:nth-child(2) {
  left: 37.5%;
  top: 18.75%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4):nth-child(3),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4)
  ~ *:nth-child(3) {
  left: 18.75%;
  top: 37.5%;
}

.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4):nth-child(4),
.appointmentBox
  .card
  .multi-button
  button:first-child:nth-last-child(4)
  ~ *:nth-child(4) {
  left: 18.75%;
  top: 62.5%;
}

.appointmentBox .card .containerData {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background: var(--background);
  color: var(--text);
  padding: 0 5px;
}

.appointmentBox .card:hover .multi-button,
.appointmentBox .card .multi-button:focus-within {
  width: 10rem;
  height: 10rem;
}

.appointmentBox .card .multi-button button svg {
  font-size: 22px;
}

.notBookedPrevious {
  border: 2px solid red !important;
}

.bookedPrevious {
  border: 2px solid green;
}

.appointmentBox .notBookedPrevious .containerData {
  background: #eee !important;
}

.MuiTablePagination-root p {
  margin: 0;
}

.paginationList {
  padding: 0 20px;
}

.titleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.appointmentSection {
  margin: 30px 0 0 50px;
}

/* ========================================= New Appointment Desing ========================== */

.Scheduler-Page-con {
  /* border: 1px solid red; */
  padding: 20px;
}

.Scheduler-Page-Head-con {
  border-radius: 10px;
  border: 4px solid #e8e8e8;
  padding: 10px 0px;
  background-color: #eee;
}

.Scheduler-Page-Head {
  /* border: 1px solid blue; */
  margin: 20px;
}


/* /// Scheduler-Page-Head-Details */

.Scheduler-Page-Head-Details {
  /* border: 1px solid saddlebrown; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Scheduler-Page-Head-Details-con {
  /* border: 1px solid blue; */
  margin: 0px;
}

.Scheduler-Page-Head-text {
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
  margin: 0px 15px;
  border-radius: 5px;
  border: 1.5px solid var(---primaryColour);
}

.Scheduler-Page-Head-text h3 {
  font-size: 14px;
  text-transform: capitalize;
  /* border: 1px solid blue; */
  margin: 0px 10px;
  color: #023362;
}

.Scheduler-Page-Head-text h5 {
  /* border: 1px solid red; */
  /* border: 1px solid gray; */
  font-size: 13px;
  padding: 4px 6px;
  margin: 0px 0px;
  border-radius: 34px;
  /* background: linear-gradient(90deg, rgb(2 51 98), rgb(2 51 98 / 67%)); */
  color: #023362;
}

.Scheduler-Page-Head-text span {
  width: 25px;
  height: 25px;
  background: var(---primaryColour);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 100px;
  color: white;
}

/* /////////////////  Dropdown  ////////////////// */

.Scheduler-Page-Head-hospital-drop {
  display: flex;
  justify-content: flex-end;
}

/* .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input{
  color: #ffbb56 !important;
} */
/* /////////// */

.dropdown-toggle {
  border-radius: 20px;
  color: #023362 !important;
  border: none;
  padding: 8px 15px 8px 15px;
  font-size: 14px;
  /* border: 1px solid ##5263ff73; */
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font-size: 20px;
  margin: 0px 0px -4px 30px;
}

/* //////////////////////////////////////////////////////////////////////////////////////////// */

.Scheduler-Page-2Head-con {
  /* border: 1px solid red; */
  margin: 20px;
}

.page-handling-con {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: left;
}

.pagination-con {
  /* border: 1px solid red; */
  display: inline-block;
}

.page-List-Style-con {
  /* border: 1px solid green; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.page-btn-controll-con {
  background-color: white;
  display: inline-block;
  border-radius: 100px;
  margin-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;
  height: 43px;
}

.page-btn-controll-con.offLineAndOnline{
  border: 2px solid #023362;
}

.page-btn-controll-con span{
  width: 50%;
  margin: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.page-btn-controll-con span svg{
  color: #023362;
  font-size: 1.2rem;
}

.page-btn-controll-con button {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  bottom: 0;
  border-radius: 50px;
  border: 2px solid #023362;
  background-color: #023362;
  transition: 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-btn-controll-con button.grid{
  left: -1px;
}

.page-btn-controll-con button.list{
  left: 37px;
}

.page-btn-controll-con button.online{
  background-color: green;
  border: 2px solid green;

}

.page-btn-controll-con button.offLine{
  background-color: red;
  border: 2px solid red;

}

.page-btn-controll-con button svg{
  color: #fff;
}

/* .Toggler-Overlay {
  position: absolute;
  top:0;
  left:0;
  border: 2px solid red;
  height: 10px;
} */

/* ////////////////////////// Section 2 ////////////////////////////////// */

.Shedular-data-con-Main {
  /* border: 1px solid red; */
  padding: 20px;
}

.Shedular-data-con {
  /* border: 1px solid blue; */
  padding: 20px;
  border: 4px solid #e8e8e8;
  border-radius: 10px;
  background-color: #eee;
}

/* Box card  */

.Shedular-box-type-card {
  /* border: 1px solid sandybrown; */
  margin: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  /* height: 210px; */
  min-height: 113px;;
}

.Availeble {
  background: rgb(0 30 255 / 15%);
  color: blue;
  border: 2px solid blue;
}

.Availeble .Shedular-Box-card-Date p {
  color: blue;
}

.Booked {
  background: rgba(255, 0, 0, 0.1);
  border: 2px solid red;
  cursor: pointer;
}

/* /////// */

.NotBookedColor {
  background-color: gray;
}

.BookedColor {
  background-color: rgba(255, 0, 0, 0.678);
}
.VisitedColor {
  background-color: rgba(0, 245, 0, 0.674);
}

.AvailableColor {
  background-color: rgba(0, 30, 255, 0.736);
}

.Booked .Shedular-Box-card-data h6 {
  color: red;
  text-transform: capitalize;
}

.Booked .Shedular-Box-card-data h5 {
  color: red;
}

.Booked .Shedular-Box-card-Date p {
  color: red;
}

.Booked .Shedular-Box-card-More-icon svg {
  color: red;
}

.Booked .appointmentStepIcon {
  background: rgba(255, 0, 0, 0.3);
}

.Booked .appointmentStepIcon svg {
  color: red;
}

.Success {
  border: 2px solid rgb(1, 155, 1);
  background: rgba(0, 245, 0, 0.1);
  /* color: green !important; */
  cursor: pointer;
}

.Success-list-card{
  border: 2px solid rgb(1, 155, 1);
  background: rgba(0, 245, 0, 0.1);
  border-left: 5px solid rgb(1, 155, 1);
}

.Success h6 {
  color: green !important;
}

.Success h5 {
  color: green !important;
}

.Success .Shedular-Box-card-Date .appointmentStepIcon svg {
  color: green !important;
}

.Success .Shedular-Box-card-Date p {
  color: green !important;
}

.Success .Shedular-Box-card-More-icon svg {
  color: green;
}

.Done {
  background-color: rgb(0 0 0 / 12%);
  border: 2px solid gray;
  cursor: not-allowed;
}

.Done .Shedular-Box-card-Date p {
  color: #808080;
}

.Shedular-Box-card-head {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  height: 45px;
  width: 45px;
  border-radius: 30px;
  margin: 5px 0px 0px 0px;
}

.Shedular-Box-card-head img {
  height: 100%;
  width: 100%;
  border-radius: 30px;
}


.Shedular-Box-card-head h4 {
  font-size: 13px;
  margin: 0px;
  width: 100%;
}

.Shedular-Box-card-Time-icon {
  height: 4vh;
  width: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Shedular-Box-card-Time-icon svg {
  font-size: 25px;
  color: #023362;
}

.Shedular-Box-card-More-icon {
  /* border: 1px solid gold; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.Shedular-Box-card-More-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0px 0px 0px;
}

.Shedular-Box-card-More-icon svg {
  color: whitesmoke;
  font-size: 25px;
  background-color: none;
}

.Shedular-Box-card-Deatils {
  /* border: 1px solid blue; */
  margin: 10px 0px 0 0;
}

/* .Shedular-Box-card-data-icon {
  background: linear-gradient(90deg, rgb(2 51 98), rgb(2 51 98 / 67%));
  margin: 0px 10px 0px 0px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.Shedular-Box-card-data-icon svg {
  font-size: 15px;
  margin: 7px;
  color: white;
}

/* .Shedular-Box-card-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

/* /////// */
.MuiToolbar-root {
  /* border: 1px solid black; */
  padding: 0px;
}

.MuiTablePagination-selectLabel {
  margin: 0px;
}

.MuiTablePagination-displayedRows {
  margin: 0px;
}


.MuiButtonBase-root svg {
  color: black !important;
}

.MuiTablePagination-selectLabel {
  color: #023362;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-bottom: 5px;
}

.MuiTablePagination-displayedRows {
  color: #023362;
  font-size: 14px !important;
  font-weight: 600 !important;
}


/* ////////// */

/* //////// Date ////// */
.TimeDisplay {
  /* border: 1px solid blue; */
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.TimeDisplay h6 {
  margin: 0px;
  display: inline-block;
  padding: 10px;
  color: #023362;
  border-radius: 10px;
  box-shadow: 0 0 2px gray;
  font-size: 0.95rem;
}

.TimeDisplay p {
  margin: 0px 0px 5px 30px;
  font-size: 30px;
}

/* //// Profile  */

/* color Card List  */
.list_view_dropdown {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  right: 100px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 225, 176);
  border-radius: 50px;
  padding: 0 0 5px 0;
}

.Availeble-List-Sub-Card {
  background-color: #92e8a6;
}

.Booked-List-Card {
  background-color: #ff384a;
  height: 12vh;
  width: 95vh;
}

/* .Booked-List-Sub-Card {
  background-color: #ff6975;
} */

/* /////////////////////// */

.Shedular-List-Data-profile-con {
  /* border: 1px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.Shedular-List-Data-profile-icon svg {
  margin: 0px;
  padding: 5px;
  font-size: 25px;
  color: white;
}

.Shedular-List-Data-profile-Name {
  margin: 0px 20px;
  text-align: left;
}

/* //// Age  */

.Shedular-List-Data-Age-con-Main {
  /* border: 1px solid black; */
  padding: 5px 0px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px 0px 20px;
  /* background-color: #92e8a6; */
}

.Shedular-List-Data-Age-icon-con {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 4px 0px;
}

.Shedular-List-Data-Age-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid greenyellow; */
  border-radius: 30px;
  background-color: #023362;
  height: 25px;
  width: 25px;
}

.Shedular-List-Data-Age-icon svg {
  margin: 0px;
  padding: 5px;
  font-size: 25px;
  color: white;
}

.Shedular-List-Data-Age-Name {
  margin: 0px 10px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Shedular-List-Data-Age-Name h6 {
  margin: 0px;
  font-size: 13px;
  text-transform: capitalize;
  color: #023362;
}

/* //// Contact  */

.Shedular-List-Data-Contact-con-Main {
  /* border: 1px solid black; */
  padding: 5px 0px;
  border-radius: 15px;
  /* background-color: #92e8a6; */
}

.Shedular-List-Data-Contact-icon-con {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 4px 0px;
}

.Shedular-List-Data-Contact-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid greenyellow; */
  border-radius: 30px;
  background-color: #023362;
  height: 25px;
  width: 25px;
}

.Shedular-List-Data-Contact-icon svg {
  margin: 0px;
  padding: 5px;
  font-size: 25px;
  color: white;
}

.Shedular-List-Data-Contact-Name {
  margin: 0px 10px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Shedular-List-Data-Contact-Name h6 {
  margin: 0px;
  font-size: 13px;
  text-transform: capitalize;
  color: #023362;
}

/* ///////////////////// NEW CHANGES ??????????????????????????/////// */

.Scheduler-Page-Head-Datepicker .MuiFormControl-root {
  border-radius: 5px !important;
}

.Shedular-Box-card-More-icon .MuiButtonBase-root svg {
  font-size: 25px;
  color: whitesmoke;
}

.Shedular-Box-card-More-icon button {
  width: auto;
  min-width: fit-content;
  margin: 0px;
  padding: 0px;
}



.Shedular-Box-card-data {
  /* border: 1px solid blue; */
  text-align: left;
}


.Shedular-Box-card-data h6 {
  font-size: 16px;
  margin: 0px 0px 3px 0px;
  color: whitesmoke;
  letter-spacing: 1px;
  color: #111 !important;
  font-weight: 600;
}

.Shedular-Box-card-Date {
  /* border: 1px solid red; */
  display: flex;
  margin: 20px 0px 0px 0px;
  justify-content: space-between;
  align-items: center;
}

.Shedular-Box-card-Date p {
  margin: 0px;
  font-size: 12px;
  font-weight: 600;
  padding: 0px 5px 0px 0px;
  color: whitesmoke;
}

.Shedular-Box-card-Date span {
  font-size: 12px;
  font-weight: 600;
  padding: 0px 0px 0px 5px;
  color: whitesmoke;
}

/* ///////////////////////// New css List ////////////////////////////// */

.Done-List-Card {
  width: 100%;
  background-color: rgb(0 0 0 / 12%);
  border: 2px solid gray;
  cursor: not-allowed;
  border-left: 5px solid gray;

}


.Shedular-List-Data-con {
  padding: 0px 10px;
  border-radius: 15px;
  display: flex;
  height: 9vh;
}

.Scheduler-Page-Head-Datepicker .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: unset !important;
}

.Shedular-List-Data-profile-con-Main {
  /* border: 1px solid black; */
  padding: 0px 20px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0px 96px 0px 0px; */
}

.Shedular-List-Data-profile-Name h6 {
  margin: 0px;
  font-size: 12px;
  text-transform: capitalize;
  color: #023362;
}

.Shedular-List-Data-profile-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #444;
  border-radius: 30px;
  /* background-color: #023362; */
  height: 45px;
  width: 45px;
}

.Shedular-List-Data-profile-icon img {
  height: 100%;
  width: 100%;
  border-radius: 30px;
}

.Shedular-List-Data-profile-Name h5 {
  margin: 0px 0px 3px 0px;
  font-size: 14px;
  text-transform: capitalize;
  color: #023362;
}

.Available-List-Card {
  /* background-color: whitesmoke; */
  width: 100%;
  border: 2px solid #5263ff;
  background-color: rgb(0 30 255 / 15%);
  border-left: 5px solid #5263ff;
}

.Shedular-Box-card-data h5 {
  color: whitesmoke;
  letter-spacing: 1px;
  font-size: 13px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #808080 !important;
}

.Booked-List-Card {
  width: 100%;
  border: 2px solid #ff384a;
  background-color: rgba(255, 0, 0, 0.1);
  border-left: 5px solid #ff4d5d;
}

/* //////////////// */

.MuiTablePagination-actions button {
  margin: 0px 5px;
  padding: 2px;
  background-color: var(---primaryColour);
  color: white;
}

.MuiTablePagination-actions button svg {
  color: #808080 !important;
  cursor: pointer;
}

.Scheduler-Page-Head-Datepicker .MuiStack-root .MuiFormControl-root {
  /* background-color: #fff1da; */
  /* color: #ffbb56; */
  border: 1.5px solid var(---primaryColour) !important;
  color: black !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root:hover svg {
  color: var(---primaryColour) !important;
}

.hospitalSelect {
  border-radius: 5px;
  color: #111;
  border: unset;
  font-weight: 500;
  border: 1.5px solid var(---primaryColour);
  background-color: #eee;
}

.hospitalSelect:focus {
  border-radius: 5px;
  color: #111;
  border: unset;
  font-weight: 500;
  box-shadow: unset;
  border: 1.5px solid var(---primaryColour);
}

.MuiTablePagination-displayedRows {
  color: #023362;
}

.Box-page-btn-controll svg {
  color: #5263ff;
}

.List-page-btn-controll svg {
  color: #5263ff;
}

.MuiPaper-root {
  border-radius: 10px;
}

.MuiPaper-root ul {
  /* border: 1px solid red; */
  padding: 5px 10px;
}

.MuiPaper-root ul li svg {
  height: 100%;
  width: 100%;
}

/* card dropdown  */

.Shedular-Box-card-More-icon .dropdown-toggle::after {
  display: none;
}

.Shedular-Box-card-More-icon .dropdown-toggle {
  background-color: transparent !important;
}

.Shedular-Box-card-More-icon #Clinic {
  min-width: fit-content !important;
  background-color: white;
  transform: translate3d(-20px, 36.3333px, 0px) !important;
  box-shadow: 0 0 2px gray;
}

.dropdown-item:active {
  color: gray !important;
  background-color: none !important;
}

.list_view_dropdown .dropdown-item,
.Shedular-Box-card-More-icon .dropdown-item {
  padding: 0px 0px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list_view_dropdown .dropdown-menu,
.Shedular-Box-card-More-icon .dropdown-menu {
  padding: 10px;
}

.Shedular-Box-card-More-icon .dropdown-menu svg {
  font-size: 17px !important;
  color: #474747;
  margin: 0px 0px 0px 6px;
}

.Shedular-Box-card-More-icon .dropdown-menu li {
  padding: 5px 5px;
  border-radius: 25px;
  /* background-color: whitesmoke; */
  font-size: 10px;
}

.Shedular-Box-card-More-icon ul {
  padding: 5px 5px;
  /* background-color: transparent; */
  border: none;
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input:focus {
  background-color: none !important;
}

.Shedular-Box-card-More-icon .dropdown-item:hover {
  background-color: transparent !important;
}

.cardaddicon {
  color: #5263ff !important;
}

.carddeleteicon {
  color: #ff384a !important;
}

.cardvitalicon {
  color: #ffbb56 !important;
}

.cardaddicon svg {
  color: #5263ff !important;
}

.carddeleteicon svg {
  color: #ff384a !important;
}

.cardvitalicon svg {
  color: #ffbb56 !important;
}

.stepButton .appointmentStepIcon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-40%);
  background-color: rgb(255, 225, 176);
  cursor: pointer;
  color: #111;
  width: 30px;
  height: 30px;
}

.appointmentStepIcon {
  background: rgb(1 155 1 / 28%);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #444;
  cursor: pointer;
}

/* .Shedular-Color-Con {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
} */

/* .Shedular-Color {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0px 10px;
} */

.Shedular-Color h4 {
  font-size: 13px;
  margin: 0px;
}

.Shedular-color-code {
  height: 15px;
  width: 15px;
  border-radius: 50px;
  margin-right: 10px;
}

.Shedular-color-code h4 {
  font-size: 12px;
}


/* ================================= Refersh Appointment ========================= */

.refershPage{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.refreshing svg{
  animation: rotateSpiner 1s linear infinite;
}

.refershPage svg{
  font-size: 1.4rem;
  color: #023362;
  margin-right: 5px;
}

@keyframes rotateSpiner {
  0%{
    transform: rotate(360deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

.refershPage h5{
  font-size: 1rem;
  margin: 0;
}