.Verification-Image-con {
    margin-top: 70px;
    /* border: 1px solid blue; */
}

.Verification-form {
    /* border: 1px solid red; */
    width: 320px;
    padding: 0px 30px 30px 30px;
}

.Verification-Image {
    /* border: 1px solid black; */
    height: 13vh;
    width: 13vh;
    margin-left: 115px;
    padding: 20px;
    background-color: #4C489D;;
    border-radius: 50px;
}

.Verification-Image img {
    height: 100%;
    width: 100%;
}

.Verification-Text-con {
    /* border: 1px solid blue; */
    margin: 10px 0px;
    display: flex;
    justify-content: left;
}

.Verification-Text {
    display: inline-block;
    margin-left: 55px;
}

.Verification-Text-con h4 {
    margin-bottom: 10px;
    color: #4C489D;;
}

.Verification-Text-con p {
    color: #6c63ac;
}

.otpin{
    margin: 0px 30px 0px 30px;
}

.otpin-con{
    margin-bottom: 20px !important;
}

.resendbtn button{
    padding: 5px 10px;
    border-radius: 50px;
    background-color: #6c63ac;
    color: white;
    border: none;
    visibility: hidden;
}

.otpin-con input{
    border: 1px solid #6c63ac;
    border-radius: 3px;
    box-shadow: 0px 0px 3px #6c63ac;
    margin: 2px 0px;
}

.resendbtn span{
    color: #6c63ac;
}

.NumberIcon svg{
font-size: 20px;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    visibility: visible;
}