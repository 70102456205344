.addDrugBoxHead {
  text-align: center;
  padding: 5px 0px;
  background-color: #eee;
  margin-bottom: 15px;
}

.addDrugBoxHead h6 {
  margin: 0;
}

.drugDetail .input-style-1 input[type="text"],
.drugDetail .input-style-1 input[type="number"],
.strengthDetailSection .input-style-1 input,
.strengthDetailSection .input-style-1 select,
.durationSection input,
.durationSection select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: rgb(195, 195, 195) 1px solid;
}

.showGenericInput,
.oneCapsuletext {
  margin-top: 10px;
}

.drugTypeSection,
.oralSection,
.drugDetail .input-style-1,
.addStrength,
.durationSection,
.drugInstruction,
.relationWithFood {
  margin-top: 15px;
}
/* 
.Facilities-Section2-tabpanel form{
    margin-bottom: 60px;
} */

.buttonList {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
}
.strengthUnitSelect select {
  background-color: #f3f3f3;
  box-shadow: unset;
  border: 1.5px solid #808080;
  /* border-radius: 50px; */
  padding: 7px 45px 7px 20px;
  overflow: hidden;
  font-size: 14px;
}

.buttonList button,
.buttonList span {
  color: #1976d2;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border: unset;
  margin-left: 5px;
}

.buttonList button:hover {
  background-color: transparent;
  border: unset;
}

.Oralbtn {
  border-radius: 50px !important;
  color: #111 !important;
  background-color: whitesmoke !important;
  font-size: 16px !important;

  text-transform: capitalize !important;
  border: none !important;
  padding: 3px;
  transition: all 0.6s ease-in-out 0s;
  display: flex;
  width: 80px;
  justify-content: center;
  align-items: center;
}
.Nonoralbtn {
  border-radius: 50px !important;
  color: #111 !important;
  background-color: whitesmoke !important;
  font-size: 16px !important;
  padding: 3px;
  text-transform: capitalize !important;
  border: none !important;

  transition: all 0.6s ease-in-out 0s;
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

/* ===================== Oral Switch Button ====================== */

.oralButtons {
  width: 200px;
  height: 35px;
  border-radius: 5px;
  background-color: #aaa;
  display: flex;
  align-items: center;
  position: relative;
}

.oralButtons span {
  height: 35px;
  width: 50%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.oralButtons .scrollButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 75%;
  border: unset;
  border-radius: 4px;
  width: 50%;
  background-color: var(---primaryColour);
  color: #fff;
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.oralButtons .scrollButton.oral {
  left: 5px;
}

.oralButtons .scrollButton.nonOral {
  left: 95px;
}
