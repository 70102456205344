.referencesHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 30px;
    border-bottom:1px solid #eee;

}

.referencesHead .addReferenceBtn button {
    margin: 0px 10px;
    background: #4a6cf7;
    color: #fff;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    padding: 4px 8px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}

.referencesHead h6 {
    font-size: 18px;
    padding:20px 0 0 0;
}

.referencesListSection {
    margin: 20px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
    border-radius: 8px;
}

.referencesListSection .editIcon svg{
    color: #4a6cf7;
}

.referencesListSection .deleteIcon svg {
    color: #d70000;
}