.notificationSection {
  padding: 0 20px;
}

.notificationDetails {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notificationUsed {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.notificationUsed h5 {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  border: 2px solid #111;
  padding: 10px 25px;
  border-radius: 10px;
  width: 230px;
}

.notificationUsed img {
  width: 45px;
  margin-right: 15px;
}

.notificationUsed h5 span:first-child {
  font-size: 1.7rem;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: #000;
}

.notificationUsed h5 span:last-child {
  font-size: 1.2rem;
  color: #444;
}

.smsBookButtons {
  display: flex;
  align-items: center;
  justify-content: left;
}

.smsBookButtons button {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notificationRight img {
  object-fit: cover;
  width: 200px;
  height: 200px;
  overflow: hidden;
  transform: scale(2);
  margin-right: 200px;
  position: relative;
  z-index: 1;
}

.smsSetting{
    margin-bottom: 30px;
}
.smsSetting h3{ 
    color: var(---primaryColour);
    margin-bottom: 30px;
 }
.checkList{
    padding-left: 30px;
}
.checkList .form-check{
    padding: 5px 0;
    display: flex;
    align-items: center;
}

.checkList .form-check label{
    font-size: 1.2rem;
    margin-left: 10px;
    color: #000;
}