
.AddHeandoutHead {
    text-align: center;
    background-color: #eee;
    padding: 8px;
}

.AddHeandoutHead h6 {
    margin-bottom: 0;
}

.addHandoutContent .input-style label {
    margin-top: 18px;
}

.addHandoutContent .input-style input{
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: rgb(195, 195, 195) 1px solid;
    margin: 0 5px;
}