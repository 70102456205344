/* Header */
.handoutsHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }
  .handoutsHead h5 {
    position: relative;
    font-size: 25px;
    color: var(---primaryColour);
    font-family: var(---primaryFont);
  }
  
  .handoutList .handoutsHead h5 {
    color: #fff;
    position: relative;
    background-color: #111;
    padding: 15px 15px;
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .handoutList .handoutsHead h5 button {
    background-color: transparent;
    border: unset;
  }
  .handoutList .handoutsHead h5 svg {
    color: #c5041c;
    font-size: 1.4rem;
  }
  
  .searchBox {
    padding: 0px 10px;
    position: relative;
  }
  .searchBox svg {
    position: absolute;
    right: 25px;
    top: 8px;
  }
  .searchBox svg {
    font-weight: bold;
    font-size: 25px;
  }
  .searchBox input {
    width: 100%;
    border-radius: 5px;
    padding: 8px 15px;
    border: 1px solid #808080;
  }
  .searchBtn button {
    background: #4a6cf7;
    color: #fff;
    padding: 6px 8px;
    border: #4a6cf7 solid 1px;
    border-radius: 5px;
  }
  
  .searchBox span {
    color: #808080;
    font-size: 0.9rem;
  }
  
  /* ========== Handout List ========== */
  
  .handoutListSection .trendingList,
  .handoutListSection .libraryList {
    background-color: #fff;
  }
  
  /* ======== Sub-list Items =========== */
  
  .subListItem {
    padding: 10px;
  }
  
  .subListItemName h6 {
    margin-bottom: 0;
  }
  
  .subListItem .accordion-header .accordion-button {
    padding: 0px;
  }
  
  .handoutListSection {
    display: flex;
    padding: 0 0 10px 10px;
  }
  .handoutCatogeryList {
    margin: 0 0 0 10px;
    transition: 0.5s ease-in-out;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 83vh;
  }
  
  .handoutCatogeryList::after {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: #808080;
  }
  
  .handoutCatogeryDataList {
    transition: 0.5s ease-in-out;
    width: 0;
    opacity: 0;
    height: 100%;
    position: relative;
    min-height: 80vh;
    transform: translateY(5vh);
    border-radius: 5px;
  }
  
  .cardData {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  
  .catogeryListCard {
    border-radius: 5px;
    max-width: 100%;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px !important;
    border-radius: 3px;
    font-size: 0.9rem;
    box-shadow: 0 0 2.5px #808080;
    width: 100%;
    text-transform: capitalize;
  }
  
  .catogeryListCard.Listactive svg {
    visibility: visible;
    opacity: 1;
    margin-right: 0;
    transition: 0.5s ease-in-out;
  }
  
  .catogeryListCard svg {
    visibility: hidden;
    opacity: 0;
    margin-right: 50px;
    transition: 0.5s ease-in-out;
  }
  
  .catogeryListCard:hover {
    transition: 0.5s ease-in-out;
    color: #111;
    background-color: #eee;
  }
  .catogeryListCard.Listactive {
    transition: 0.5s ease-in-out;
    color: #fff;
    background-color: rgb(29, 72, 113);
  }
  
  .catogeryListCard.Listactive h5 {
    color: #fff !important;
  }
  
  .catogeryListCard h5 {
    margin: 0;
    font-size: 0.9rem;
    color: #808080;
    font-weight: 500;
  }
  .dataList {
    padding: 2px 10px;
  }
  
  .dataList button {
    margin: 20px 0;
  }
  
  .handoutListData {
    margin-left: 35px;
    margin-right: 30px;
  }
  
  .handoutImage {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  .handoutImage img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .handoutTitle {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .handoutTitle h4 {
    font-size: 1.2rem;
    font-weight: 500;
  }
  
  .handoutButtonSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .handoutButtonSection a{
    background-color: transparent;
  }
  .handoutButtonSection button {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    color: #fff;
  }
  .handoutButtonSection button.link svg {
    transform: scale(1.4);
  }
  .handoutButtonSection button.link {
    background-color: #0a66c2;
    border: 2px solid #0a66c2;
  }
  
  .handoutButtonSection button.pdf {
    background-color: #0a66c2;
    border: 2px solid #0a66c2;
  }
  
  .handoutButtonSection button.image {
    background-color: #0a66c2;
    border: 2px solid #0a66c2;
  }
  
  .handoutButtonSection button:nth-child(2) {
    background-color: darkorange;
    border: 2px solid darkorange;
  }
  
  .handoutButtonSection button:nth-child(3) {
    background-color: red;
    border: 2px solid red;
  }
  
  .handoutListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .searchHandout {
    position: relative;
    width: 100%;
    max-width: 350px;
  }
  
  .searchHandout input {
    width: 100%;
    padding: 10px 25px;
    border-radius: 50px;
    border: 1px solid #808080;
  }
  
  .searchHandout span svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.6rem;
  }
  
  .newColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin: 15px 0px 15px 0px;
  }
  
  .newColumn input {
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #808080;
  }
  
  .newColumn svg {
    font-size: 1.5rem;
    margin-right: 6px;
  }
  .newColumn button {
    width: 30px;
    height: 23px;
    margin-left: 6px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  .newColumn button svg {
    font-size: 0.8rem;
    margin: unset;
  }
  
  .newColumn button.add {
    background-color: darkorange;
    border: 1px solid darkorange;
    cursor: pointer;
  }
  
  .newColumn button.add:disabled {
    background-color: rgb(255, 181, 91, 0.4);
    border: 1px solid rgb(255, 181, 91, 0.4);
    color: #808080;
    cursor: not-allowed;
  }
  
  .newColumn button.close {
    background-color: red;
    border: 1px solid red;
    color: #fff;
  }
  
  
  
  /* @media(max-width:990px) {
    .handoutCatogeryList {
        min-height: auto;
        position:fixed;
        right:0;
        top:0;
        background-color: #fff;
        max-width:300px;
        box-shadow:0 0 5px #808080;
        z-index:1000;
        padding:50px 10px;
    }
    .cutBtn
    {
        width:30px;
        height:30px;
        border-radius:50%;
        padding:3px 7px;
        background-color: #eee;
    }
   
  } */
  .categoryShow button{
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 1vh;
    background-color: rgb(0,0,0, .5);
    border: 1px solid #111;
    color: #111;
    width: 40px;
    height: 40px;
    border-radius: 50px 0px 0px 50px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
  }
  .categoryShow button svg{
    animation: settingScroll 2s infinite linear;
  }

  @keyframes settingScroll {
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
@media(max-width:990px){
  .handoutCatogeryList{
    margin: unset;
  }
}
  @media (max-width:768px) {
    .searchHandout{
      max-width: 100%;
    }
  }
