
.editDrugBoxHead h6 {
    text-align: center;
    background-color: #eee;
    padding: 10px;
}

.frequencyDurationInput input ,
.doseInput input,
.stripDetailSection input {
    width: 100%;
}

.doseInputText p{
    text-align: center;
}

.stripDetailSection {
    margin-top: 10px;
}

.autodoseCalHeadText,
.taperSectionHead {
    display: flex;
}

.autodoseCalHeadText .appplyH6,
.taperSectionHead .appplyH6 {
    color: blue;
    font-size: 14px;    
    margin-left: 5px;
}

.autoDoseCalInput .autodoseCalradioBtns label {
    margin-right: 7px;
} 

.autoCalHowMuch input {
    width: 100%;
}

.autoDoseCalInput .resetButton button {
    background: #4a6cf7;
    color: #fff;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    font-size: 13px;
    padding: 4px 8px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}
.addTaperDose h6 {
    color: blue;
}



.editDrugBody{
    margin-bottom: 50px;
}

.buttonGroup{
    text-align: right;
    padding-bottom: 20px !important;
}

.buttonGroup button{
    background-color: transparent;
    color: rgb(0 0 255);
    border: unset;
    margin: 0 10px;
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 500;
}