@import url("https://fonts.googleapis.com/css2?family=Abyssinica+SIL&family=Frank+Ruhl+Libre&family=Kanit:wght@200&family=Lora@1&family=Playfair+Display&family=Raleway&family=Roboto+Condensed&family=Roboto+Serif:opsz@8..144&family=Roboto:wght@300&family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  ---primaryColour: rgb(9, 57, 101);
  ---secondaryColour: rgb(197, 4, 28);
  ---primaryFont: "Roboto", sans-serif;
  ---secondaryFont: "Lato", sans-serif;
}

.patientImgName {
  color: var(---primaryColour) !important;
  font-weight: 500;
}

.main-container {
  display: flex;
  overflow: hidden;
  width: 100%;
}

main {
  width: 100%;
  /* background-color: #f1f5f9; */
}

.navbar-main {
  box-shadow: 0 1px 4px #808080;
}

.title {
  font-size: 2.5rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: var(---primaryColour);
  color: white;
  height: 100vh;
  overflow-y: auto;
  position: fixed;
  height: 100%;
  z-index: 10000;
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}

.logo {
  font-size: 18px;
  transform: translateY(5px);
  line-height: 0;
  color: #fff;
}

.bars {
  color: #4a6cf7;
}

.hide {
  display: none;
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.link {
  display: flex;
  color: #eee;
  gap: 10px;
  padding: 5px 10px;
  font-size: 1.1rem;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  border-right: 4px solid rgb(197, 4, 28);
  background: rgb(178, 181, 200);
  color: #fff;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

/* .active {
  border-right: 4px solid rgb(197, 4, 28);
  background: rgb(232, 233, 241);
} */

.link_text {
  white-space: nowrap;
  margin: 2px 0;
  color: #fff;
  font-size: 1rem;
}

.menu {
  display: flex;
  color: #eee;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}

.menu_item {
  display: flex;
  gap: 10px;
}

.menu_container {
  display: flex;
  flex-direction: column;
}

.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.main-wrapper {
  /* padding-bottom: 30px; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  width: 100%;
}

.access_detail {
  background: forestgreen;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.acces_info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.acces_info h5 {
  color: #fff;
  font-size: 1rem;
}

.acces_info h4 {
  font-size: 1rem;
  text-decoration: underline;
  color: #fff;
  margin-left: 10px;
}

.access_out {
  background: transparent;
  border: unset;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.header_wrapper {
  padding: 10px 0;
  background: var(---primaryColour);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-out 0s;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.main-wrapper .profile-box {
  display: flex;
  position: relative;
}

.main-wrapper .profile-box button {
  width: auto;
}

.main-wrapper .fa-caret-down {
  display: inline-block;
  font: normal normal normal 1em/1 "LineIcons";
  color: inherit;
  flex-shrink: 0;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-wrapper .profile-box .dropdown-menu {
  width: 230px;
}

.main-wrapper .profile-box .dropdown-menu.dropdown-menu-end {
  transform: translate3d(56px, 5px, 0px);
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  position: absolute;
  content: "";
  margin: 0px 7px;
  top: 23px;
  left: 58%;
  color: #5d657b;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.main-wrapper .profile-box .dropdown-menu li {
  border-bottom: none;
}

.main-wrapper .profile-box .dropdown-menu li a {
  font-size: 14px;
  display: flex;
  padding: 8px 12px;
  display: flex;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 6px;
  align-items: center;
}

.main-wrapper .profile-box .dropdown-menu li a span {
  margin-right: 15px;
  font-weight: 500;
}

.main-wrapper .profile-box .profile-info {
  margin: 0 5px;
}

.main-wrapper .profile-box .profile-info .info {
  display: flex;
  align-items: center;
}

.main-wrapper .profile-box .profile-info .info h6 {
  color: var(---secondColor);
  font-weight: 500;
  margin: unset;
}

.titleData span {
  font-size: 0.75rem;
  color: #808080;
  font-weight: 500;
}

.main-wrapper .profile-box .profile-info .info .image {
  border: 2px solid #fff;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-left: 16px;
  position: relative;
}

.main-wrapper .profile-box .profile-info .info .image .status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #e5e5e5;
  background: #219653;
  position: absolute;
  bottom: 0;
  right: 0;
  top: auto;
}

.main-wrapper .profile-box .profile-info .info .image img {
  width: 100%;
  border-radius: 50%;
}

/* ===================================================Deshbord Card======================================== */

.Deshbord .icon-card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 30px 20px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 10px 20px rgb(200 208 216 / 30%);
  border-radius: 10px;
  margin-bottom: 30px;
}

.Deshbord .icon-card .icon.purple {
  background: rgba(155, 81, 224, 0.1);
  color: #9b51e0;
}

.Deshbord .mb-10 {
  margin-bottom: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-40 {
  margin-top: 40px;
}

.Deshbord .icon-card .icon {
  max-width: 46px;
  width: 100%;
  height: 46px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 20px;
  background: rgba(74, 108, 247, 0.1);
  color: #4a6cf7;
  font-weight: 700;
}

.Deshbord h6 h3 {
  font-size: 16px;
  font-weight: 600;
  color: #262d3f;
  margin: 0;
}

.text-bold {
  font-weight: 700;
}

.navbarBodyMargin {
  margin: 120px 0 0 45px !important;
  padding: 0 10px;
}

/* ////////////////////////////////////////////////////////////// */

/* Normal Btn  */

.buttonGroups {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.buttonGroups.margin {
  margin-top: 100px;
}

.Clickbtn {
  border-radius: 7px !important;
  color: white !important;
  background-color: var(---primaryColour) !important;
  font-size: 14px !important;
  padding: 7px 15px !important;
  text-transform: capitalize !important;
  border: none !important;
  border: 2px solid var(---primaryColour) !important;
  transition: all 0.6s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.Cancelbtn {
  border-radius: 7px !important;
  color: white !important;
  background-color: rgb(197, 4, 28) !important;
  font-size: 16px !important;
  padding: 7px 15px !important;
  text-transform: capitalize !important;
  border: none !important;
  /* border: 2px solid var(---primaryColour) !important; */
  transition: all 0.6s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  font-weight: 500;
}
.submitBtn {
  border-radius: 7px !important;
  color: white !important;
  background-color: green !important;
  font-size: 16px !important;
  padding: 7px 15px !important;
  text-transform: capitalize !important;
  border: none !important;
  font-weight: 500;
  transition: all 0.6s ease-in-out 0s;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ///// Normal Btn  ///// */

.iconsStyle {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  /* border: 1px solid rgb(197, 4, 28); */
}

.iconsStyle.search {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  background-color: var(---primaryColour);
}

.iconsStyle svg {
  font-size: 1.2rem;
}

.iconsStyle.sucess {
  background-color: rgba(0, 245, 0, 0.1);
  color: green;
  border: 2px solid transparent;
  transition: all 0.6s ease-in-out 0s;
  margin: 0px 5px;
}

.iconsStyle.sucess:hover {
  background-color: transparent;
  color: green;
  border: 1px solid green;
}

.iconsStyle.delete {
  background-color: rgba(255, 0, 0, 0.1);
  color: rgb(197, 4, 28);
  border: 1px solid transparent;
  transition: all 0.6s ease-in-out 0s;
  margin: 0px 5px;
  cursor: pointer;
}

.iconsStyle.delete:hover {
  background-color: transparent;
  color: rgb(197, 4, 28);
  border: 1px solid rgb(197, 4, 28);
}

.iconsStyle.edit {
  background-color: rgb(0 30 255 / 15%);
  color: blue;
  border: 1px solid transparent;
  transition: all 0.6s ease-in-out 0s;
  margin: 0px 5px;
  cursor: pointer;
}

.iconsStyle.edit:hover {
  background-color: transparent;
  color: blue;
  border: 1px solid blue;
}

.iconsStyle.Orange {
  background-color: rgb(0 30 255 / 15%);
  color: blue;
  border: 1px solid transparent;
  transition: all 0.6s ease-in-out 0s;
  margin: 0px 5px;
}

.iconsStyle.Orange:hover {
  background-color: transparent;
  color: blue;
  border: 1px solid blue;
}

/* search btn  */

.searchDiv {
  position: relative;
  width: 100%;
  max-width: 350px;
}

/* ================== Search Input =================== */
.searchDiv input {
  background-color: #f3f3f3;
  box-shadow: unset;
  border: 1.5px solid #808080;
  border-radius: 50px;
  padding: 7px 45px 7px 20px;
  overflow: hidden;
  width: 100%;
}

/* =============== Form Input =========== */
.inputForm input {
  background-color: #f3f3f3;
  box-shadow: unset;
  border: 1.5px solid #808080;
  border-radius: 5px;
  padding: 7px 45px 7px 20px;
  overflow: hidden;
}

.inputForm input::placeholder,
.searchDiv input::placeholder {
  color: #444;
}

.inputForm input:focus,
.inputForm input:hover,
.searchDiv input:focus,
.searchDiv input:hover {
  box-shadow: unset;
  outline: unset;
  border: 1.5px solid var(---primaryColour);
}

/* ====================== Popup Titles ==================== */

.popUpFormTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.popUpFormTitle span {
  text-align: center;
  background-color: rgb(0 30 255 / 15%);
  padding: 5px 20px;
  color: var(---primaryColour);
  margin-bottom: 15px;
  border-radius: 40px;
  width: 100%;
  font-size: 16px;
}

/* ////// Send Search ////// */

/* ////////////////// Input search  ///////////////////// */

.InputStyleMain {
  background-color: whitesmoke;
  color: gray;
  padding: 0.375rem 0.75rem;
  border: none;
}

.TextareaStyleMain {
  background-color: whitesmoke;
  margin: 20px 0px 0px 0px;
}

.LabelStyle label {
  margin: 10px 0px 0px 10px;
}

.nav-tabs .nav-link.active {
  color: white;
  background-color: rgb(9, 57, 101);
}
.nav-link {
  color: rgb(9, 57, 101);
}
.nav-link:hover {
  color: rgb(9, 57, 101);
}

.form-check-input:checked {
  background-color: var(---primaryColour);
  border: 1px solid var(---primaryColour);
}

.form-check-input:focus {
  box-shadow: none;
  border: 1px solid var(---primaryColour);
}

.form-check-input[type="radio"] {
  border-radius: 50%;
  box-shadow: none;
}

/* -------------------reference responsive card --------------------- */
.customerCard {
  /* display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  background: #fff;
  box-shadow: 0 3px 10px;
  height: 150px;
  border-radius: 10px;
}

.customerCard span {
  font-size: 2.5rem;
  font-weight: 500;
  font-family: var(---primeColor);
  text-transform: capitalize;
  color: #111;
}

.customerCard h5 {
  text-align: center;
  font-size: 1rem;
  color: #111;
  font-weight: 500;
  text-transform: capitalize;
}
.smallButton {
  display: flex;
}

/* ========================= Arrow Csss =========================== */
.arrowHint {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 25px 0;
}
.arrowHint .arrowIcon {
  width: 45px;
  height: 45px;
  background-color: transparent;
  border: 2px solid #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-bottom: 10px;
}

.arrowHint .arrowIcon svg {
  color: #808080;
  font-size: 1.6rem;
  animation: bounceArrow 1.2s linear infinite;
}

@keyframes bounceArrow {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-8px);
  }
}
@media (max-width: 990px) {
  .customerCard {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 3px 10px;
    height: auto;
    border-radius: 10px;
    margin: 10px 0;
    width: 100%;
    text-align: left;
  }
  .customerCard ul {
    width: 100%;
    padding: 10px 50px;
    /* padding:0; */
    margin: 0;
  }
  .customerCard ul li {
    line-height: 30px;
  }
  .customerCard span {
    font-size: 1rem;
    font-weight: 600;
    font-family: var(---secondaryFont);
    text-transform: capitalize;
    color: #111;
    margin-left: 10px;
  }
  .customerCard h5 {
    text-align: center;
    font-size: 1rem;
    color: #111;
    font-weight: 500;
    text-transform: capitalize;
  }
}

@media (max-width: 500px) {
  .customerCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0px 5px #808080;
    border-radius: 10px;
    margin: 10px 0;
    padding: 10px;
  }
  .customerCard span {
    font-size: 1rem;
    font-weight: 500;
    font-family: var(---robotFont);
    text-transform: capitalize;
    color: #111;
  }
  .customerCard h5 {
    text-align: center;
    font-size: 1rem;
    color: #111;
    font-weight: 500;
    text-transform: capitalize;
  }
}
.template {
  max-width: 100%;
  width: 100%;
  padding: 5px;
  position: relative;
  z-index: 100;
}

/* ============================= Search Dropdown List ========================== */

.listSearchDropDown {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 0 5px #808080;
  border-radius: 7px;
  background-color: #fff;
  max-height: 120px;
  overflow-y: scroll;
  z-index: 10;
}

/* width */

.listSearchDropDown ul {
  padding: 10px 10px;
  margin: 0;
}

.listSearchDropDown ul li {
  padding: 7px 5px;
  border-bottom: 0.5px solid #afafaf;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.listSearchDropDown ul li:hover {
  background-color: #eee;
}

.listSearchDropDown ul li:last-child {
  border: unset;
}

/* =============================== MUI Setting ================== */

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px 14px !important;
}

.addAppointmentForm .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgb(0, 0, 0, 0.3) !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #111 !important;
}

.form-select:hover,
.form-control:focus {
  border: 2px solid #111 !important;
  box-shadow: unset;
  outline: unset;
}
.css-1uvydh2 {
  padding: 10px 0px 10px 14px !important;
}
.css-igs3ac {
  border: unset !important;
}

.Scheduler-Page-Head-Datepicker .css-1bn53lx {
  border: unset !important;
}

.css-1bn53lx {
  border: 1px solid !important;
  border-color: #afafaf !important;
}
.css-p0rm37.Mui-error {
  color: #808080 !important;
  transform: translate(14px, 10px) scale(1) !important;
}

.css-1ald77x {
  background-color: #fff !important;
}
/* ======================== Accordion Css ==================== */
.accordion {
  padding: 0 5px;
}
.accordion-item {
  background-color: #fff;
  border: unset !important;
  margin: 15px 0;
  box-shadow: 0 0 3px #808080;
}
.accordion-button .accordionIcon {
  background-color: rgb(0 30 255 / 15%);
  color: var(---primaryColour);
  border: 1px solid transparent;
  transition: all 0.6s ease-in-out 0s;
  margin: 0px 5px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordion-button .accordionIcon:hover {
  background-color: transparent;
  color: var(---primaryColour);
  border: 1px solid var(---primaryColour);
}

.accordion-button:focus {
  border: unset;
  box-shadow: unset;
}

.accordion-button:not(.collapsed) {
  background-color: #e5e5e5;
  color: #111;
}

.accordion-button:not(.collapsed) {
  color: var(---primaryColour);
}

/* ============================= Allergy CSS ================= */

.drugAllergyList {
  margin: 0 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}

.drugAllergyList .AllergyList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: grab;
}

.drugAllergyList .AllergyList span {
  padding: 3px 7px;
  margin-right: 7px;
  border: 2px solid #808080;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  background: #111;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drugAllergyList .AllergyList span button {
  background-color: transparent;
  border: unset;
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  margin-left: 5px;
  padding: 0;
  cursor: pointer;
}

.AllergyList::-webkit-scrollbar {
  height: 0px;
}

/* ========================= Page Title ===================== */

.pageTitle {
  color: var(---primaryColour);
  font-weight: 500;
  font-size: 2.2rem;
  text-transform: capitalize;
  position: relative;
}

.pageTitle::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 5rem;
  height: 2px;
  background-color: var(---primaryColour);
}

/* ======================== Custom Scroll Bar ====================== */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #093965 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #093965;
  border-radius: 20px;
  border: 4px solid #ffffff;
}

.receptionHeader {
  margin-top: 80px;
}

@media (max-width: 900px) {
  .pageTitle {
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .navbarBodyMargin {
    margin: 80px 0 0 0px !important;
  }

  .phoneScreen {
    position: fixed;
    top: -300%;
    z-index: 1000;
    height: 100vh;
    width: 100%;
    bottom: 0;
    background-color: rgb(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out;
  }
  .phoneScreen .Scheduler-Page-con {
    height: 100%;
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .phoneScreen .Scheduler-Page-Head-con {
    height: 100%;
    width: 100%;
    max-height: 550px;
  }
  .showPhoneScreen {
    top: 0;
  }
  .pageTitle {
    font-size: 1.5rem;
  }
  .CategoryCard {
    flex-direction: column;
  }
  .CategoryCard .categButton {
    position: unset !important;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  .doctorView .CategoryCard .categText {
    margin: 6px 0px !important;
  }
}

@media (max-width: 500px) {
  .Scheduler-Page-Head-Details {
    display: grid !important;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    width: 100%;
    justify-content: unset !important;
  }
  .Scheduler-Page-Head-text {
    padding: 8px 12px 8px 12px;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 2px;
    margin: 0 !important;
    justify-content: space-between;
    border-radius: 30px;
    border: 1px solid var(---primaryColour);
  }
}

.borderDrug {
  border-bottom: 1.5px solid #afafaf;
}

.borderDrug:last-child {
  border-bottom: unset !important;
}

.otherDetailAppointment {
  position: fixed;
  top: 50%;
  left: 45px;
  transform: translateY(-50%);
  padding: 15px 7px;
  border: unset;
  background-color: #fff;
  color: #111;
  font-weight: 500;
  font-size: 1.2rem;
  box-shadow: 0 0 3px #808080;
  z-index: 1000;
  border-radius: 0 5px 5px 0;
  transition: 0.5s ease-in-out;
}

@media (max-width: 601px) {
  .otherDetailAppointment {
    left: 0px;
  }
}
button:disabled {
  cursor: no-drop !important;
  pointer-events: visible !important;
  opacity: 0.6;
  color: #fff;
}

.lessSmsRemaining {
  width: 100%;
  text-align: center;
  border-radius: 50px;
  max-width: 800px;
  margin: auto;
  color: #fff;
  padding: 3px;
}

.lessSmsRemaining.warning {
  background-color: #e47d00;
}

.lessSmsRemaining.error {
  background-color: red;
}

.css-1x5jdmq {
  border: 1.5px solid #111 !important;
}

.css-1x5jdmq {
  padding: 9px 5px !important;
  font-size: 0.89rem;
}

.css-qiwgdb.css-qiwgdb.css-qiwgdb{
  padding: 10px 0 !important;
}


.MuiTablePagination-select
{
  box-shadow: 0 0 0 2px #808080;
  background-color: #fff !important;
  border-radius: 5px !important;
}

.MuiTablePagination-selectIcon{
  color: #111;
}
