.addAppointmentBox {
  padding: 0px;
}

/* header */

.appointmentAddBoxHeader {
  text-align: center;
  background-color: #e5e5e5;
  padding: 5px 0px;
  margin-bottom: 20px;
}

.appointmentAddBoxHeader h6 {
  margin: 0;
}

/* ================= Form =================== */

.addAppointmentBox form {
  width: 100%;
  position: relative;
}

.input-style-1 {
  position: relative;
}

.searchBoxList {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  opacity: 0;
  z-index: 100000;
}

.activeSearchBox {
  top: 63px !important;
  visibility: visible !important;
  transition: 0.3s ease-in-out !important;
  opacity: 1 !important;
}

.SearchData {
  width: 100%;
  max-height: 150px;
  box-shadow: 0 0 4px #808080;
  border-radius: 4px;
  background-color: #fff;
  overflow-x: hidden;
  padding: 5px;
}

.patientList span {
  font-size: 0.7rem;
}

.patientList h5 {
  font-size: 0.8rem;
  margin: 0;
}

.titleList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.css-camg44-MuiPaper-root-MuiAlert-root {
  position: fixed;
  right: 0;
  top: 20%;
}

.patientList{
  padding: 0 5px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  position: relative;
  border-bottom:1px solid #eee ;
}

.patientList:last-child{
  border: unset;
}


.patientList:hover{
  background: #eee;
    padding: 0 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.SearchData::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.SearchData::-webkit-scrollbar-track {
  background: unset; 
}
 
/* Handle */
.SearchData::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
.SearchData::-webkit-scrollbar-thumb:hover {
  background: #555; 
}