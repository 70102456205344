.patientsProfileHead {
  font-family: var(---headFont);
  text-align: center;
  margin-top: 20px;
}

.patientProfileDetails {
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 10px 20px rgb(200 208 216 / 50%);
}

.patientProfileDetails .input-style-1 label,
.patientProfileDetails .smsHeading {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #262d3f;
  display: block;
  margin-bottom: 8px;
}

.addressLable {
  visibility: hidden;
}

.patientProfileDetails .input-style-1 input,
.patientProfileDetails .input-style-1 select {
  width: 100%;
  background: rgba(239, 239, 239, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px;
  color: #5d657b;
  resize: none;
  transition: all 0.3s;
}

.patientDetailSubmit {
  display: flex;
  justify-content: flex-start;
}

.patientDetailSubmit button {
  margin-top: 20px;
  background: #4a6cf7;
  color: #fff;
  border: 1px solid #4a6cf7;
  border-radius: 5px;
  font-size: 17px;
  padding: 8px 10px;
}

.AddPatientSection .card-style .form-control:focus {
  box-shadow: none;
  overflow: unset;
  border-color: #86b7fe;
}

.AddPatientSection .profileImage {
  border-color: #ffffff;
  position: relative;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  max-width: 300px;
  max-height: 300px;
  background-color: #eee;
  /* box-shadow: 0 0 5px #b8b8b8; */
}

.AddPatientSection .profileImage img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  object-fit: cover;
}

.AddPatientSection .profileImage label {
  position: absolute;
  top: -20px;
  right: -15px;
  /* border: 1px solid; */
  border-radius: 50%;
  /* padding: 11px; */
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #000;
  color: whitesmoke;
}
