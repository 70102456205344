.otpin{
    margin: 0px 30px 0px 30px;
}

.otpin-con{
    margin-bottom: 20px !important;
}

.resendbtn button{
    padding: 5px 10px;
    border-radius: 50px;
    background-color: #6c63ac;
    color: white;
    border: none;
    visibility: hidden;
}

.otpin-con input{
    border: 1px solid #6c63ac;
    border-radius: 3px;
    box-shadow: 0px 0px 3px #6c63ac;
    margin: 2px 0px;
}

.resendbtn span{
    color: #6c63ac;
}

.NumberIcon svg{
font-size: 20px;
}