.inputBoxInoutInnerDiv:nth-child(4),
.inputBoxInoutInnerDiv:nth-child(5),
.inputBoxInoutInnerDiv:first-child{
    flex: 1 0 100px;
    padding: 5px;
}

.inputBoxInoutInnerDiv:nth-child(2),
.inputBoxInoutInnerDiv:nth-child(3){
    flex: 1 0 150px;
    padding: 5px;
}