.viewHistorySection {
    padding: 15px;
}

.historyHeads .historyHeadLink span {
    margin: 0px 5px;
}

.historyHeadLink span svg {
    font-size: 22px;
}

.viewHistoryTabContent {
    background-color: #fff;
}


/* ================= Record Histoy ======================= */

/* Medical History */
.recordHistoryTab {
    background-color: #fff;
    padding: 10px;
}

.medicalRecordSection .medicalRecordDate,
.medicalRecordSection .medicalRecordContent {
    border-radius: 6px;
    padding: 8px;
    box-shadow: 0 0 3px #808080;
}

/* Records and Images */

.recordsAndImgSection .recordPrescription,
.recordsAndImgSection .recordImages {
    background-color: #fff;
    padding: 20px 30px;
}

.recordPrescription p ,
.recordImages p{
    text-align: center;
    font-family: var(---headFont);
}

.recordPrescription .recordBtn,
.recordImages .recordBtn {
    display: flex;
    justify-content: flex-end;
}

.recordPrescription button,
.recordImages button {
    background: #4a6cf7;
    color: #fff;
    margin: 4px 4px;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    font-size: 17px;
    padding: 8px 10px;
}

.prescriptionImgSection{
    width: 150px; 
    height: 100%;
    margin: auto;
}

.prescriptionImgSection .prescriptionImgCard{
    width: 100%;
    height: 100%;
}

.prescriptionImgHead {
    background-color: #4a6cf7;
    padding: 3px;
}

.prescriptionImgHead p {
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
}

.prescriptionImgSection .prescriptionImg img {
    width: 100%;
    height: 100%;
}

.prescriptionImgFooter {
    display: flex;
    justify-content: space-between;
    background-color: #4a6cf7;
    padding: 3px 10px;
}

.prescriptionImgFooter .prscFootIcon svg {
    color: #fff;
    font-size: 15px;
}

/* ==================== History Section =================== */

.historyTypeSection {
    background-color: #fff;
    padding: 10px;
}

/* =================== Vital History ======================= */

.vitalHistorySection {
    padding: 10px;
}

.vitalHistorySection .vitalHistoryInner {
    border: 1px solid blue;
    border-radius: 5px;
}

.vitalHistoryInner .vitalHistoryHead {
    background-color: blue;
    color: #fff;
    padding: 8px 15px;
}

.vitalHistoryInner .VitalHistoryInput {
    padding: 18px 10px;
}

.vitalHistoryInner .VitalHistoryInput p {
    margin-bottom: 0;
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 5px 8px;
}

/* ==================== Assistant's Note ======================== */

.assistantNoteSection p {
    border: 1px solid #eee;
    border-radius: 6px;
    padding: 12px;
}

.viltalListCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 10px 0;
}

.removeButton button{
    border: unset;
    background: red;
    font-size: 1.2rem;
    padding: unset;
    margin: unset;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
}

.removeButton button::after{
    content: '';
    background-color: #fff;
    width: 15px;
    height: 3px;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dataHeading{
    color: #111;
    font-weight: 500;
    font-size: .9rem;
}

.dataText{
    font-size: .8rem;
    color: #808080;
    font-weight: 400;
}

.vitalInfo.active .dataHeading{
    color: var(---FourthColor);
    font-weight: 500;
}

.vitalInfo.active .dataText{
    color: #111;
    font-weight: 500;
    font-size: .85rem;
}

.vitalData{
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0 0 4px #808080;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 10px;
}

.vitalDataList{
    display: flex;
    justify-content: center;
    align-items: center;
}

.addButtonLink{
    width: 100%;
}

.addRecords{
    width: 100%;
    text-align: left;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.addRecords button{
    border: unset;
    background: var(--bs-teal);
    font-size: 1rem;
    padding: unset;
    margin: unset;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    padding: 10px 20px;
}

.addRecords button svg{
    font-size: 1.2rem;
    color: #fff;
    margin: 0 20px 0 0;
}

.detailData{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleView{
    font-size: .85rem;
    font-weight: 500;
    color: #111;
}

.viewInput{
    font-size: .85rem;
    color: #111;
}

.text-muted{
    font-size: .8rem;
    color: #808080;
}

.growthModal.css-ypiqx9-MuiDialogContent-root{
    padding: 10px 14px;
    min-width: 255px;
}

.viewInput{
    position: absolute;
    left: 50%;
    transform: translateX(350%);
}
.vitalInfo{
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.vitalSection{
    padding: 0 10px;
}

.GrowthSection,
.dateSection {
    box-shadow: 0 0 3px #808080;
    border-radius: 5px;
    position: relative;
    width: 100%;
}

.dateSection input,
.dateSection input:focus{
    box-shadow: unset !important;
    border: unset !important;
    outline: unset !important;
}

.GrowthSection{
    padding: 10px;
}

.vitalForm h4{
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 15px;
    color: var(---FourthColor);
    position: relative;
    padding-bottom: 10px;
}

.vitalForm h4::after{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2rem;
    height: 1.5px;
    background-color: var(---FourthColor);
}

 .vitalForm form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
} 

.vitalForm  div.mb-3{
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 0 40px 0 0;
}

.vitalForm  div.mb-3 label{
    max-width: 45%;
    text-align: right;
    width: 100%;
}
.vitalForm  div.mb-3 small{
    width: 100%;
    max-width: 25%;
    text-align: left;
}

.vitalForm .buttonGroup button{
    margin-bottom: 10px;
    padding: 7px;
    border-radius: 50px;
    background: green;
    border: 2px solid green;
    color: #fff;
    width: 150px;
}

.viltalListCard{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 10px 0;
}

.removeButton button{
    border: unset;
    background: red;
    font-size: 1.2rem;
    padding: unset;
    margin: unset;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
}

.removeButton button::after{
    content: '';
    background-color: #fff;
    width: 15px;
    height: 3px;
    border-radius: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dataHeading{
    color: #111;
    font-weight: 500;
    font-size: .9rem;
}

.dataText{
    font-size: .8rem;
    color: #808080;
    font-weight: 400;
}

.vitalInfo.active .dataHeading{
    color: var(---FourthColor);
    font-weight: 500;
}

.vitalInfo.active .dataText{
    color: #111;
    font-weight: 500;
    font-size: .85rem;
}

.addButtonLink{
    width: 100%;
}

.viewAll button{
    border: unset;
    background: var(--bs-teal);
    font-size: 1.2rem;
    padding: unset;
    margin: unset;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewAll button svg{
    font-size: 1rem;
    color: #fff;
}

.detailData{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.titleView{
    font-size: .85rem;
    font-weight: 500;
    color: #111;
}

.viewInput{
    font-size: .85rem;
    color: #111;
}

.text-muted{
    font-size: .8rem;
    color: #808080;
}

.growthModal.css-ypiqx9-MuiDialogContent-root{
    padding: 10px 14px;
    min-width: 255px;
}

.viewInput{
    position: absolute;
    left: 50%;
    transform: translateX(350%);
}

.vitalInputData{
    display: flex;
    justify-content: center;
}