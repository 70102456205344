.drugHead {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
}

.drugListHeading h6{
    font-family: var(---primaryFont);
    font-size:16px;
}
.drugHeadButtons button,
.searchDrugBtn button {
    margin: 0px 10px;
    background: #4a6cf7;
    color: #fff;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    padding: 4px 8px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;
}

.drugSearchResetBtn button {
    margin: 0px 10px;
    background: #fff;
    color: #4a6cf7;
    border: 1px solid #4a6cf7;
    border-radius: 5px;
    padding: 4px 8px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 5px;

} 

.drugListSection {
    padding: 20px 20px;
    background-color: #fff;
    /* padding: 15px; */
    margin:15px;
    box-shadow: 0px 10px 20px rgb(200 208 216 / 30%);
    border-radius: 6px;
  
}
.drugListDataIcon
{

        background-color: rgba(255, 0, 0, .1);
        color: red;
        border: 1px solid transparent;
        transition: all .6s ease-in-out 0s;
        margin: 0px 5px;
      
}
.drugListHeadingData .drugRow
{
    margin:20px 0;
}
.DrugsPageSearchBox {
    margin: 5px 15px;
    padding: 5px 0px;
    box-shadow: 0px 10px 20px rgb(200 208 216 / 30%);

    border-radius: 10px;
}

.DrugsPageSearchBox .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root{
    background-color: #fff;
 }
 
 .DrugsPageSearchBox .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
     transform: translate(12px, 9px) scale(1);
 }
 
 .DrugsPageSearchBox .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
     padding: 8px 14px;
 }

 /* Normal Btn  */
/* 
.Clickbtn {
    border-radius: 50px !important;
    color: #fff !important;
    background-color: var(---primaryColour) !important;
    font-size: 16px !important;
    padding: 5px 18px !important;
    text-transform: capitalize !important;
    border: none !important;
    border: 2px solid var(---primaryColour) !important;
    transition: all .6s ease-in-out 0s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Clickbtn:hover {
    color: var(---primaryColour) !important;
    background: white !important;
    border: 2px solid var(---primaryColour) !important;
  } */

 